import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { EyeOutlined, EyeFilled } from "@ant-design/icons";
import { Button } from "antd";
import style from "./ResetPassword.module.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const initialValues: MyFormValues = {
  email: "",
  token: "",
  password: "",
  passwordConfirm: "",
};

interface MyFormValues {
  email: string;
  token: string;
  password: string;
  passwordConfirm: string;
}

const Component = (props: any) => {
  const {
    codeSended,
    loading,
    sendCodeHandler,
    сheckCodeHandler,
    codeChecked,
    ressetPasswordHandler,
  } = props;

  const [showPass, setIsShowPass] = useState(false);
  const [showResetPass, setIsShowResetPass] = useState(false);

  const ResetPassSchema = Yup.object().shape({
    email: codeChecked
      ? Yup.string()
      : Yup.string().email("Недійсна пошта").required("Заповніть поле"),
    token: codeSended ? Yup.string().required("Заповніть поле") : Yup.string(),
    password: codeChecked
      ? Yup.string()
          .min(8, ({ min }) => `Пароль повинен містити ${min} символів`)
          .required("Заповніть поле")
      : Yup.string(),
    passwordConfirm: codeChecked
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Паролі не співпадають")
          .required("Заповніть поле")
      : Yup.string(),
  });

  return (
    <div className={style.container}>
      <h1 className={style.title}>Відновлення паролю</h1>
      <Formik
        validationSchema={ResetPassSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (codeChecked) {
            return ressetPasswordHandler(values);
          }
          if (codeSended) {
            return сheckCodeHandler(values);
          }
          sendCodeHandler(values);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form className={style.form}>
            {!codeChecked ? (
              <>
                <label className={style.label} htmlFor="email">
                  Пошта
                </label>
                <Field
                  disabled={codeSended}
                  className={[
                    style.input,
                    errors.email && touched.email && style.inputError,
                  ].join(" ")}
                  id="email"
                  type="email"
                  name="email"
                  placeholder=""
                />
                {errors.email && touched.email ? (
                  <div className="formError">{errors.email}</div>
                ) : null}
                {codeSended && (
                  <>
                    <label className={style.label} htmlFor="token">
                      Код
                    </label>
                    <Field
                      className={[
                        style.input,
                        errors.token && touched.token && style.inputError,
                      ].join(" ")}
                      id="token"
                      type="text"
                      name="token"
                      placeholder=""
                    />
                    {errors.token && touched.token ? (
                      <div className="formError">{errors.token}</div>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                <label className={style.label} htmlFor="password">
                  Пароль
                </label>
                <div className={style.passwordBlock}>
                  <Field
                    className={[
                      style.input,
                      errors.password && touched.password && style.inputError,
                    ].join(" ")}
                    type={showPass ? "text" :"password"}
                    id="password"
                    name="password"
                    placeholder=""
                  />
                  {showPass ? (
                    <EyeFilled
                      style={{ fontSize: 28 }}
                      onClick={() => {
                        setIsShowPass(!showPass);
                      }}
                      className={style.showPassIcon}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setIsShowPass(!showPass);
                      }}
                      style={{ fontSize: 28 }}
                      className={style.showPassIcon}
                    />
                  )}
                </div>
                {errors.password && touched.password ? (
                  <div className="formError">{errors.password}</div>
                ) : null}
                <label className={style.label} htmlFor="passwordConfirm">
                  Підтвердити пароль
                </label>
                <div className={style.passwordBlock}>
                  <Field
                    className={[
                      style.input,
                      errors.passwordConfirm &&
                        touched.passwordConfirm &&
                        style.inputError,
                    ].join(" ")}
                    type={showResetPass ? "text" :"password"}
                    id="passwordConfirm"
                    name="passwordConfirm"
                    placeholder=""
                  />
                  {showResetPass ? (
                    <EyeFilled
                      style={{ fontSize: 28 }}
                      onClick={() => {
                        setIsShowResetPass(!showResetPass);
                      }}
                      className={style.showPassIcon}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setIsShowResetPass(!showResetPass);
                      }}
                      style={{ fontSize: 28 }}
                      className={style.showPassIcon}
                    />
                  )}
                </div>
                {errors.passwordConfirm && touched.passwordConfirm ? (
                  <div className="formError">{errors.passwordConfirm}</div>
                ) : null}
              </>
            )}

            <Button
              disabled={loading}
              loading={loading}
              className={style.submitBtn}
              htmlType="submit"
              type="primary"
            >
              {codeChecked ? "Зберегти" : "Відновити"}
            </Button>
          </Form>
        )}
      </Formik>
      <div className={style.linkBlock}>
        <Link className={style.fogotPass} to="/login">
          Я пам'ятаю пароль, увійти
        </Link>
      </div>
    </div>
  );
};

export default Component;
