import React, { useEffect, useState } from "react";
import style from "../CourseCreateScreen.module.css";
import { Field, Form, Formik } from "formik";
import JoditEditor from "jodit-react";
import { DeleteFilled } from "@ant-design/icons";
import * as Yup from "yup";
import { Button } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Component = (props: any) => {
  const {
    initialValuesCourse,
    createCourseHandle,
    loading,
    config,
    lecturesInitial,
  } = props;
  const CoursesSchema = Yup.object().shape({
    title: Yup.string().required("Заповніть поле"),
    description: Yup.string().required("Заповніть поле"),
    purpose: Yup.string().required("Заповніть поле"),
    detailed_description: Yup.string().required("Заповніть поле"),
  });

  const [lectures, setLectures] = useState<any>([]);

  const renderFields = (count: number) => {
    let elements = [];
    for (let i = 1; i <= count; i++) {
      elements.push(i);
    }
    return elements;
  };

  useEffect(() => {
    setLectures(renderFields(lecturesInitial));
  }, [lecturesInitial]);

  const getListStyle = (isDraggingOver: any) => ({
    background: "#f8f7faq",
    padding: grid,
    height: lectures?.length * 135,
  });

  const grid = 1;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: "#f8f7fa",
    position: "relative",
    width: "100%",
    flexDirection: "column",
    display: "flex",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <Formik
      validateOnChange={false}
      validationSchema={CoursesSchema}
      validateOnBlur={false}
      initialValues={initialValuesCourse}
      onSubmit={(values, actions) => {
        createCourseHandle(values);
        actions.setSubmitting(false);
      }}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const onDragEnd = (result: any) => {
          // dropped outside the list
          if (!result.destination) {
            return;
          }

          const items = reorder(
            lectures,
            result.source.index,
            result.destination.index
          );

          items?.forEach((el, index) => {
            setFieldValue(`lection${index + 1}`, values[`lection${el}`]);
            if (values[`lectid${el}`]?.length !== 0) {
              setFieldValue(`lectid${index + 1}`, values[`lectid${el}`]);
            }
          });
        };

        const onDelete = (elem: any) => {
          const items = lectures.slice(0, -1);

          setFieldValue(`lection${elem}`, "");
          setFieldValue(`lectid${elem}`, "");

          setLectures(items);

          for (let i = elem; i <= items?.length; i++) {
            setFieldValue(`lection${i}`, values[`lection${i + 1}`]);
            if (values[`lectid${i}`]) {
              setFieldValue(`lectid${i}`, values[`lectid${i + 1}`]);
            }
          }

          setFieldValue(`lection${items?.length + 1}`, "");
          setFieldValue(`lectid${items?.length + 1}`, "");
        };

        return (
          <Form className={style.form}>
            <label className={style.label} htmlFor="title">
              Назва курсу
            </label>
            <Field
              className={[
                style.input,
                errors.title && touched.title && style.inputError,
              ].join(" ")}
              id="title"
              type="title"
              name="title"
              placeholder=""
            />
            {errors.title && touched.title ? (
              <div className="formError">{errors.title}</div>
            ) : null}
            <label
              className={style.label}
              style={{ marginBottom: 15 }}
              htmlFor="description"
            >
              Опис
            </label>
            <JoditEditor
              value={values.description}
              config={config}
              onChange={(newContent) =>
                setFieldValue("description", newContent)
              }
            />
            {errors.description && touched.description ? (
              <div className="formError">{errors.description}</div>
            ) : null}
            <h3 className={style.subTitle}>Деталі курсу</h3>
            <label className={style.label} htmlFor="purpose">
              Чого ви навчитеся
            </label>
            <Field
              className={[
                style.input,
                errors.purpose && touched.purpose && style.inputError,
              ].join(" ")}
              id="purpose"
              type="purpose"
              name="purpose"
              placeholder=""
            />
            {errors.purpose && touched.purpose ? (
              <div className="formError">{errors.purpose}</div>
            ) : null}
            <label
              className={style.label}
              style={{ marginBottom: 15 }}
              htmlFor="detailed_description"
            >
              Опис
            </label>
            <JoditEditor
              value={values.detailed_description}
              config={config}
              onChange={(newContent) =>
                setFieldValue("detailed_description", newContent)
              }
            />
            {errors.detailed_description && touched.detailed_description ? (
              <div className="formError">{errors.detailed_description}</div>
            ) : null}
            <h3 className={style.subTitle}>Контент</h3>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {lectures?.map((el: any, index: number) => {
                      return (
                        <Draggable
                          key={el}
                          draggableId={String(el)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={el}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <label
                                className={style.label}
                                htmlFor={`lection${el}`}
                              >
                                Лекція {el}
                              </label>
                              <Field
                                className={[style.input].join(" ")}
                                id={`lection${el}`}
                                type={`lection${el}`}
                                name={`lection${el}`}
                                placeholder=""
                              />
                              {lectures?.length > 1 && (
                                <Button
                                  onClick={() => onDelete(el)}
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    fontSize: 36,
                                    right: -60,
                                    top: 65,
                                  }}
                                  type="link"
                                >
                                  <DeleteFilled
                                    style={{
                                      color: "red",
                                    }}
                                  />
                                </Button>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div>
              <Button
                onClick={() => setLectures(renderFields(lectures?.length + 1))}
                className={style.linkBtn}
                type="link"
              >
                Додати лекцію
              </Button>
            </div>
            <h3 className={style.subTitle}>Проекти</h3>
            {renderFields(values.projectsNumber)?.map((el, index) => {
              return (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <label className={style.label} htmlFor={`project${el}`}>
                    Проект {el}
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id={`project${el}`}
                    type={`project${el}`}
                    name={`project${el}`}
                    placeholder=""
                  />
                  {index === values.projectsNumber - 1 && index !== 0 && (
                    <Button
                      onClick={() => {
                        setFieldValue(
                          "projectsNumber",
                          values.projectsNumber - 1
                        );
                        setFieldValue(`project${el}`, "");
                      }}
                      style={{
                        color: "red",
                        position: "absolute",
                        fontSize: 36,
                        right: -60,
                        top: 65,
                      }}
                      type="link"
                    >
                      <DeleteFilled
                        style={{
                          color: "red",
                        }}
                      />
                    </Button>
                  )}
                </div>
              );
            })}
            <div>
              <Button
                onClick={() =>
                  setFieldValue("projectsNumber", values.projectsNumber + 1)
                }
                className={style.linkBtn}
                type="link"
              >
                Додати проект
              </Button>
            </div>
            <div>
              <Button
                disabled={loading}
                loading={loading}
                className={style.submitBtn}
                htmlType="submit"
                type="primary"
              >
                Зберегти
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Component;
