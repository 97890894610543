import * as actions from "../actions/actionsType";

const noop: any = (): any => {};

export const initialAuthState = {
  isLogin: false,
  isMentor: false,
  token: "",
  login: noop,
  logout: noop,
  userName: "",
  ready: false,
  getUserHandler: noop,
  userID: "",
  webinaresList: null,
  getWebinarList: noop,
};

export const authReducer = (state = initialAuthState, action: any) => {
  switch (action.type) {
    case actions.SET_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      };
    case actions.SET_MENTOR:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    case actions.SET_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case actions.SET_USER_ID:
      return {
        ...state,
        userID: action.userID,
      };
    case actions.SET_WEBINARS:
      return {
        ...state,
        webinaresList: action.webinaresList,
      };
    default:
      return state;
  }
};
