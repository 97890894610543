import React from "react";
import style from "./WebinaresCreateScreen.module.css";
import "antd/dist/antd.css";
import { PictureOutlined, DeleteFilled } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { Field, Form, Formik } from "formik";
import JoditEditor from "jodit-react";
import { timezones } from "../../../utils/timezones";
import * as Yup from "yup";

require("dayjs/locale/uk");

const Component = (props: any) => {
  const {
    initialValuesWebinar,
    webinarLoading,
    loading,
    courses,
    createWebinarHandler,
    webinarImg,
    setWebinarImg,
    setWebinarImgFile,
    deleteWebinareImage,
  } = props;

  const ValidateSchema = Yup.object().shape({
    date: Yup.string().required("Заповніть поле"),
    start_time: Yup.string().required("Заповніть поле"),
    course: Yup.string().required("Заповніть поле"),
    speaker: Yup.string().required("Заповніть поле"),
    title: Yup.string().required("Заповніть поле"),
    timezone: Yup.string().required("Заповніть поле"),
    description: Yup.string().required("Заповніть поле"),
  });

  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <div className={style.headerBlockTexts}>
          <h1>{"Вебінари"}</h1>
        </div>
      </div>
      <div className={style.formLection}>
        {webinarLoading ? (
          <div
            style={{ backgroundColor: "#ffffff", maxWidth: 675 }}
            className={style.loaderContainer}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Formik
            validateOnChange={false}
            validationSchema={ValidateSchema}
            validateOnBlur={false}
            initialValues={initialValuesWebinar}
            onSubmit={(values, actions) => {
              createWebinarHandler(values);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className={style.leftColumn}>
                  {webinarImg ? (
                    <div
                      style={{ position: "relative" }}
                      className={style.video}
                    >
                      <img
                        style={{ objectFit: "cover" }}
                        className={style.video}
                        src={webinarImg}
                        alt="Заставка"
                      />
                      <div
                        onClick={() => {
                          const del = window.confirm(
                            "Ви дійсно хочете видалити зображення?"
                          );
                          if (del) {
                            deleteWebinareImage(values?.id);
                          }
                        }}
                        style={{
                          right: 20,
                          top: -20,
                          color: "red",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteFilled style={{ fontSize: 34, color: "red" }} />
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="upload" className={style.addImage}>
                      <div className={style.video}>
                        <PictureOutlined style={{ fontSize: 92 }} />
                        <span>Додати зображення</span>
                        <input
                          style={{ visibility: "hidden", width: 0 }}
                          id="upload"
                          type="file"
                          accept="image/png,image/jpeg"
                          onChange={(event: any) => {
                            if (event?.target !== null) {
                              let reader = new FileReader();
                              let file = event.target.files[0];
                              setWebinarImgFile(file);
                              reader.onloadend = () => {
                                setWebinarImg(reader.result);
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                        />
                      </div>
                    </label>
                  )}

                  <label className={style.label} htmlFor="title">
                    Назва
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id="title"
                    type="text"
                    name="title"
                    placeholder=""
                  />
                  {errors.title && touched.title ? (
                    <div className="formError">{errors.title}</div>
                  ) : null}
                  <label
                    className={style.label}
                    style={{ marginBottom: 15 }}
                    htmlFor="description"
                  >
                    Опис
                  </label>
                  <JoditEditor
                    value={values.description}
                    onChange={(newContent) =>
                      setFieldValue("description", newContent)
                    }
                  />
                  {errors.description && touched.description ? (
                    <div className="formError">{errors.description}</div>
                  ) : null}
                  <label className={style.label} htmlFor="date">
                    Дата проведення:
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id="date"
                    lang="ua"
                    type="date"
                    name="date"
                    placeholder=""
                  />
                  {errors.date && touched.date ? (
                    <div className="formError">{errors.date}</div>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "50%",
                        flexDirection: "column",
                        display: "flex",
                        marginRight: 10,
                      }}
                    >
                      <label className={style.label} htmlFor="start_time">
                        Час проведення:
                      </label>
                      <Field
                        className={[style.input].join(" ")}
                        id="start_time"
                        type="time"
                        name="start_time"
                        placeholder=""
                      />
                      {errors.start_time && touched.start_time ? (
                        <div className="formError">{errors.start_time}</div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        flexBasis: "50%",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <label className={style.label} htmlFor="timezone">
                        Часовий пояс
                      </label>
                      <Field
                        className={[style.input].join(" ")}
                        name="timezone"
                        as="select"
                        placeholder=""
                      >
                        <option defaultValue={""}></option>
                        {timezones.map((el: any) => (
                          <option value={el.text}>{el.text}</option>
                        ))}
                      </Field>
                      {errors.timezone && touched.timezone ? (
                        <div className="formError">{errors.timezone}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className={style.label} htmlFor="speaker">
                    Спікер
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id="speaker"
                    type="text"
                    name="speaker"
                    placeholder=""
                  />
                  {errors.speaker && touched.speaker ? (
                    <div className="formError">{errors.speaker}</div>
                  ) : null}
                  <label className={style.label} htmlFor="zoom_url">
                    Зум кімната
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id="zoom_url"
                    type="text"
                    name="zoom_url"
                    placeholder=""
                  />
                  <label className={style.label} htmlFor="course">
                    З якого курсу
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    value={values.course}
                    as="select"
                    placeholder=""
                    name="course"
                  >
                    <option defaultValue={""}></option>
                    {courses.map((el: any) => (
                      <option value={el.id}>{el.title}</option>
                    ))}
                  </Field>
                  {errors.course && touched.course ? (
                    <div className="formError">{errors.course}</div>
                  ) : null}

                  <label className={style.label} htmlFor="url">
                    Посилання на запис курсу
                  </label>
                  <Field
                    className={[style.input].join(" ")}
                    id="url"
                    type="text"
                    name="url"
                    placeholder=""
                  />
                  <div>
                    <Button
                      disabled={loading}
                      loading={loading}
                      className={style.submitBtn}
                      htmlType="submit"
                      type="primary"
                    >
                      Зберегти
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <div className={style.rightColumn}></div>
      </div>
    </div>
  );
};

export default Component;
