import React, { useContext } from "react";
import style from "../CourseCreateScreen.module.css";
import { Field, Form, Formik } from "formik";
import JoditEditor from "jodit-react";
import { Button, Spin } from "antd";

const Component = (props: any) => {
  const {
    loading,
    initialValuesProject,
    config,
    courseInfo,
    curentProject,
    projectLoading,
    setCurentProject,
    saveProjectInfo
  } = props;

  return (
    <div className={style.formLection}>
      {projectLoading ? (
        <div
          style={{ backgroundColor: "#ffffff", maxWidth: 675 }}
          className={style.loaderContainer}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValuesProject}
          onSubmit={(values, actions) => {
            saveProjectInfo(values);
            actions.setSubmitting(false);
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form style={{ width: "100%" }} className={style.leftColumn}>
              <h3 className={style.subTitle}>{values.title}</h3>
              <label
                className={style.label}
                style={{ marginBottom: 15 }}
                htmlFor="description"
              >
                Опис
              </label>
              <JoditEditor
                value={values.description}
                config={config}
                onChange={(newContent) =>
                  setFieldValue("description", newContent)
                }
              />
              <label className={style.label} htmlFor="url">
                Посилання на завдання
              </label>
              <Field
                className={[style.input].join(" ")}
                id="url"
                type="url"
                name="url"
                placeholder=""
              />
              <div>
                <Button
                  disabled={loading}
                  loading={loading}
                  className={style.submitBtn}
                  htmlType="submit"
                  type="primary"
                >
                  Зберегти
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className={style.rightColumn}>
        {courseInfo?.project?.map((el: any) => {
          return (
            <button
              key={el.id}
              style={
                curentProject === el.id ? { backgroundColor: "#F8F8F8" } : {}
              }
              className={style.lectureBtn}
              onClick={() => setCurentProject(el.id)}
            >
              {el.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Component;
