import React, { useContext, useEffect, useState } from "react";
import { useHttp, useMessage } from "../../../utils/hooks";
import CourseOpenScreen from "./CourseOpenScreen";
import { useParams } from "react-router-dom";
import style from "./CourseOpenScreen.module.css";
import { baseUrl } from "../../../utils/config";
import { Spin } from "antd";

const CourseCreateScreenContainer = (props: any) => {
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();
  const params = useParams();

  const [course, setCourse] = useState<any>(null);
  const [lectures, setLectures] = useState<any>(null);
  const [selecterdLecture, setSelecterdLecture] = useState<any>(null);

  useEffect(() => {
    if (params?.id) {
      fetchCoursesByIdHandler(params?.id);
    }
  }, []);

  useEffect(() => {
    if (course?.status) {
      fetchLecturesHandler(course?.id);
    }
  }, [course]);

  const fetchCoursesByIdHandler = async (id: string) => {
    try {
      const req = await request(`${baseUrl}/api/student/courses/${id}/`, "GET");
      if (req) {
        setCourse(req);
      }
    } catch (e) {}
  };

  const fetchLecturesHandler = async (id: string) => {
    try {
      const req = await request(
        `${baseUrl}/api/student/courses/${id}/lectures/`,
        "GET"
      );
      if (req) {
        setLectures(req);
        if (req.length > 0) {
          setSelecterdLecture(req[0].id);
        }
      }
    } catch (e) {}
  };

  const fetchRegisterToCourse = async (id: string) => {
    try {
      const req = await request(
        `${baseUrl}/api/student/courses/${id}/register/`,
        "POST",
        {}
      );
      if (req) {
        let newCourse = { ...course, status: true };
        setCourse(newCourse);
      }
    } catch (e) {}
  };

  const fetchHelpHandler = async (data: any, closeModal: any) => {
    try {
      const req = await request(`${baseUrl}/api/student/help/`, "POST", data);

      if (req) {
        message("Ваше запитання успішно надіслане", false);
        closeModal();
      }
    } catch (e) {}
  };

  const fetchCompeleteLecture = async (id: string) => {
    try {
      const req = await request(
        `${baseUrl}/api/student/lecture/${id}/view/`,
        "POST",
        {}
      );
      if (req) {
        let newCourse = { ...course, status: true };
        setCourse(newCourse);
      }
    } catch (e) {}
  };

  if (!course) {
    return (
      <div className={style.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <CourseOpenScreen
      course={course}
      lectures={lectures}
      fetchRegisterToCourse={fetchRegisterToCourse}
      selecterdLecture={selecterdLecture}
      setSelecterdLecture={setSelecterdLecture}
      fetchHelpHandler={fetchHelpHandler}
      loading={loading}
      fetchCompeleteLecture={fetchCompeleteLecture}
      {...props}
    />
  );
};

export default CourseCreateScreenContainer;
