import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import WebinaresScreen from "./WebinaresScreen";

const WebinaresScreenContainer = (props: any) => {
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();

  const [webinaresList, setWebinaresList] = useState(null);
  const [curentWebinarId, setCurentWebinarId] = useState(null);
  const [curentWebinar, setCurentWebinar] = useState(null);
  const [loadingCurentWebinar, setLoadingCurentWebinar] = useState(false);

  useEffect(() => {
    message(errorMessage, true);
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error?.detail) {
      message(error?.detail, true);
    }
    clearError();
  }, [error, message, clearError]);

  useEffect(() => {
    getWebinarList();
  }, []);

  useEffect(() => {
    if (curentWebinarId) {
      getWebinar(curentWebinarId);
    }
  }, [curentWebinarId]);

  const getWebinarList = async () => {
    try {
      const req = await request(`${baseUrl}/api/mentor/webinars/`, "GET");
      if (req) {
        setWebinaresList(req);
        setCurentWebinarId(req[0].id);
      }
    } catch (e) {}
  };

  const getWebinar = async (id: string) => {
    setLoadingCurentWebinar(true);
    try {
      const req = await request(`${baseUrl}/api/mentor/webinars/${id}/`, "GET");
      if (req) {
        setCurentWebinar(req);
        setLoadingCurentWebinar(false);
      }
    } catch (e) {}
  };

  return (
    <WebinaresScreen
      curentWebinarId={curentWebinarId}
      webinaresList={webinaresList}
      curentWebinar={curentWebinar}
      setCurentWebinarId={setCurentWebinarId}
      loadingCurentWebinar={loadingCurentWebinar}
      {...props}
    />
  );
};

export default WebinaresScreenContainer;
