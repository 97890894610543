import React, { useState } from "react";
import style from "./StudentsScreen.module.css";
import dayjs from "dayjs";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

require("dayjs/locale/uk");

const ProjectItem = (props: any) => {
  const { proj, setStudentProjectStatus } = props;
  const [status, setStatus] = useState(proj?.completed);
  return (
    <div style={{ flexBasis: "16.5%" }} className={style.projectBlock}>
      <div className={style.projectBlockTitle}>{proj?.project_title}</div>
      <div className={style.projectBlockBtns}>
        <button
          style={status ? { color: "#5BBE2C" } : {}}
          className={style.projectBlockBtn}
          onClick={() => {
            setStudentProjectStatus(proj.id, true);
            setStatus(true);
          }}
        >
          Виконана
        </button>
        <button
          style={!status ? { color: "red" } : {}}
          className={style.projectBlockBtn}
          onClick={() => {
            setStudentProjectStatus(proj.id, false);
            setStatus(false);
          }}
        >
          Не виконана
        </button>
      </div>
    </div>
  );
};

const StudentItem = (props: any) => {
  const { el, setStudentProjectStatus } = props;
  const [showDeatils, setShowDetails] = useState(false);

  let completedProjects = 0;

  el?.project?.forEach((e: any) => {
    if (e?.completed) {
      completedProjects = completedProjects + 1;
    }
  });

  return (
    <div className={style.tableBody}>
      <div className={style.tableBodyTop}>
        <div className={style.tableBodyColumn}>
          {el?.user?.first_name} {el?.user?.last_name}
        </div>
        <div style={{ flexBasis: "20%" }} className={style.tableBodyColumn}>
          {el?.user?.email}
        </div>
        <div className={style.tableBodyColumn}>{el?.course_title}</div>
        <div style={{ marginLeft: 20 }} className={style.tableBodyColumn}>
          <span
            style={{
              color:
                el?.lecture_completed === el?.lecture_total &&
                completedProjects === el?.project?.length
                  ? "#5BBE2C"
                  : "#ffbf00",
            }}
          >
            {el?.lecture_completed === el?.lecture_total &&
            completedProjects === el?.project?.length
              ? "Завершено"
              : "В процесі"}
          </span>
        </div>
        <div className={style.tableBodyColumn}>
          {el?.date
            ? dayjs(el?.date).locale("uk").format("D MMMM YYYY")
            : el?.date}
        </div>
        <div
          style={{ cursor: "pointer", color: "#a548ff", fontWeight: "bold" }}
          className={style.tableBodyColumn}
          onClick={() => setShowDetails(!showDeatils)}
        >
          {!showDeatils ? (
            <>
              Детально <DownOutlined />
            </>
          ) : (
            <>
              Згорнути <UpOutlined />
            </>
          )}
        </div>
      </div>
      {showDeatils && (
        <div className={style.tableBodyBottom}>
          <div className={style.tableProgress}>
            <div className={style.tableProgressPercent}>
              {" "}
              Прогрес -{" "}
              {((el?.lecture_completed * 100) / el?.lecture_total).toFixed(0)}%
            </div>
            <div className={style.tableProgressBar}>
              <div
                style={{
                  width: `${(
                    (el?.lecture_completed * 100) /
                    el?.lecture_total
                  ).toFixed(0)}%`,
                }}
                className={style.tableProgressBarInside}
              />
            </div>
          </div>
          {el?.project?.map((proj: any) => (
            <ProjectItem
              key={proj.id}
              proj={proj}
              setStudentProjectStatus={setStudentProjectStatus}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Component = (props: any) => {
  const { studentList, setStudentProjectStatus } = props;
  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <div className={style.headerBlockTexts}>
          <h1>{"Студенти"}</h1>
        </div>
      </div>
      <div>
        <div className={style.tableHeader}>
          <div className={style.tableHeaderColumn}>Ім'я</div>
          <div style={{ flexBasis: "20%" }} className={style.tableHeaderColumn}>
            Пошта
          </div>
          <div className={style.tableHeaderColumn}>Курс</div>
          <div style={{ marginLeft: 20 }} className={style.tableHeaderColumn}>
            Прогресс
          </div>
          <div className={style.tableHeaderColumn}>Дата завершення</div>
          <div className={style.tableHeaderColumn}></div>
        </div>

        {studentList &&
          studentList?.map((el: any) => (
            <StudentItem
              key={el.id}
              el={el}
              setStudentProjectStatus={setStudentProjectStatus}
            />
          ))}
      </div>
    </div>
  );
};

export default Component;
