import React, { useContext } from "react";
import ReactPlayer from "react-player";
import style from "./WebinaresScreen.module.css";
import { PictureOutlined, ClockCircleFilled } from "@ant-design/icons";
import { Spin } from "antd";
import parse from "html-react-parser";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { AuthorizationContext } from "../../../store/context/authorizationContext";

require("dayjs/locale/uk");

const Component = (props: any) => {
  const { isMentor, userID } = useContext(AuthorizationContext);
  const {
    webinaresList,
    curentWebinar,
    setCurentWebinarId,
    loadingCurentWebinar,
  } = props;
  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <div className={style.headerBlockTexts}>
          <h1>{"Вебінари"}</h1>
        </div>
      </div>
      <div className={style.bodyWebinars}>
        <div className={style.leftColumn}>
          {loadingCurentWebinar ? (
            <div
              style={{ backgroundColor: "#ffffff", minWidth: 675 }}
              className={style.loaderContainer}
            >
              <Spin size="large" />
            </div>
          ) : (
            curentWebinar && (
              <>
                {curentWebinar?.url ? (
                  <ReactPlayer
                    controls={true}
                    url={curentWebinar?.url}
                    width={676}
                    height={424}
                  />
                ) : curentWebinar?.image ? (
                  <div style={{ position: "relative" }} className={style.video}>
                    <img
                      style={{ objectFit: "cover" }}
                      className={style.video}
                      src={curentWebinar?.image}
                      alt="Заставка"
                    />
                  </div>
                ) : (
                  <div className={style.video}>
                    <PictureOutlined style={{ fontSize: 92 }} />
                  </div>
                )}
                <div className={style.timeCurrent}>
                  <span>Дата та час проведення:</span>
                  <span>
                    {dayjs(curentWebinar?.date)
                      .locale("uk")
                      .format("D MMMM YYYY")}{" "}
                    - {curentWebinar?.start_time}
                  </span>
                </div>
                <div className={style.speakerCurrent}>
                  <span>Спікер:</span>
                  <span>{curentWebinar?.speaker}</span>
                </div>
                {curentWebinar?.zoom_url && (
                  <div className={style.zoomCurrent}>
                    <span>Зум кімната:</span>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={curentWebinar?.zoom_url}
                    >
                      {curentWebinar?.zoom_url}
                    </a>
                  </div>
                )}
                <h3 className={style.subTitle}>{curentWebinar?.title}</h3>
                <div className={style.description}>
                  {parse(curentWebinar?.description || "")}
                </div>
                {userID === curentWebinar?.owner && (
                  <Link
                    to={`/mentor/webinars/create/${curentWebinar?.id}`}
                    style={{ marginTop: 100 }}
                    className={style.submitBtn}
                    type="primary"
                  >
                    Редагувати
                  </Link>
                )}
              </>
            )
          )}
        </div>
        <div className={style.rightColumn}>
          {webinaresList?.map((el: any) => {
            return (
              <button
                key={el.id}
                className={style.webinarBtn}
                onClick={() => setCurentWebinarId(el.id)}
              >
                {el?.image ? (
                  <div
                    style={{ position: "relative" }}
                    className={style.videoPreview}
                  >
                    <img
                      style={{ objectFit: "cover" }}
                      className={style.videoPreview}
                      src={el?.image}
                      alt="Заставка"
                    />
                  </div>
                ) : (
                  <div className={style.videoPreview}>
                    <PictureOutlined style={{ fontSize: 15 }} />
                  </div>
                )}
                <div>
                  {el.title}
                  <div className={style.timeCurrentPreview}>
                    <ClockCircleFilled style={{ fontSize: 14 }} />
                    <span>
                      {dayjs(el?.date).locale("uk").format("D MMMM YYYY")} -{" "}
                      {el?.start_time}
                    </span>
                  </div>
                </div>
              </button>
            );
          })}
          {isMentor && (
            <Link
              to="/mentor/webinars/create"
              className={style.submitBtn}
              type="primary"
            >
              Створити
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component;
