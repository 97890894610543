import React, { useEffect, useState } from "react";
import style from "./ProjectsScreen.module.css";
import { CheckCircleFilled } from "@ant-design/icons";
import image from "./../../../assets/images/bg3.png";
import { Button } from "antd";
import parse from "html-react-parser";

const CourseItem = (props: any) => {
  const { el, fetchCoursesProjectHandler } = props;
  const [projects, setProjects] = useState<any>(null);

  useEffect(() => {
    fetchCoursesProjectHandler(el.id, setProjects);
  }, []);

  let projectsCount = (count: number) => {
    if (count === 0) {
      return "контрольних проектів";
    }
    if (count === 1) {
      return "контрольний проект";
    }
    if (count > 1 && count <= 4) {
      return "контрольні проекти";
    }
    if (count > 4) {
      return "контрольних проектів";
    }
    return "0 контрольних проектів";
  };

  return (
    <div key={el.id} className={style.bodyWebinars}>
      <div className={style.courseItem}>
        <img
          className={style.courseImage}
          src={el.image || image}
          alt="Зображення курсу"
        />
        <div className={style.courseItemInfoBlock}>
          <h3 className={style.courseItemTitle}>{el?.title}</h3>
          <div className={style.courseItemInfo}>
            <div className={style.infoItem}>
              <CheckCircleFilled style={{ fontSize: 28 }} />
              <span>
                {el?.project?.length}{" "}
                {projectsCount(el?.project?.length || null)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {projects &&
        projects?.map((el: any) => {
          return (
            <div key={el.id} className={style.projectItem}>
              <div className={style.projectNameBLock}>
                <h1 className={style.projectItemTitle}>{el.title}</h1>
                {el?.completed && (
                  <span className={style.readyProject}>Виконана</span>
                )}
              </div>
              <div>{parse(el?.description || "")}</div>
              <div>
                <Button
                  onClick={() => {
                    const w = window.open(el.url, "_blank");
                    if (w && el.url) {
                      w.focus(); // okay now
                    }
                  }}
                  disabled={el?.completed}
                  className={style.submitBtn}
                  htmlType="submit"
                  type="primary"
                >
                  {el?.completed ? "Робота оцінена" : "Виконати роботу"}
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Component = (props: any) => {
  const { coursesProjects, fetchCoursesProjectHandler } = props;

  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <div className={style.headerBlockTexts}>
          <h1>{"Мої проекти"}</h1>
        </div>
      </div>
      {coursesProjects && coursesProjects.length !== 0 && (
        <>
          {coursesProjects.map((el: any) => {
            return (
              <CourseItem
                fetchCoursesProjectHandler={fetchCoursesProjectHandler}
                el={el}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Component;
