import { useState, useCallback, useContext } from "react";
import { AuthorizationContext } from "../../store/context/authorizationContext";

const userData = "userData";

const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const { logout } = useContext(AuthorizationContext);

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}, files = false) => {
      setLoading(true);
      try {
        if (body && !files) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }

        let usersCreds = localStorage.getItem(userData);

        if (usersCreds !== null) {
          let usersCredsParse = JSON.parse(usersCreds);
          headers["Authorization"] = "Bearer " + usersCredsParse?.token;
        }

        const response = await fetch(url, { method, body, headers });
        const text = await response?.text();

        const data = text === "" ? {} : JSON.parse(text);

        if (response.status === 401) {
          logout();
        }

        if (!response.ok) {
          setError(data);
          throw new Error(data.message || "Щось пішло не так");
        }

        setLoading(false);

        return data;
      } catch (e: any) {
        setLoading(false);
        setErrorMessage(e.message);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => setError(null), []);

  const clearErrorMessage = useCallback(() => setErrorMessage(null), []);

  return {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  };
};

export default useHttp;
