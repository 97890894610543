import React from "react";
import style from "./Header.module.css";
import { FeMade } from "./../../assets";
import { Layout } from "antd";
import "antd/dist/antd.css";

const { Header } = Layout;

const Component = (props: any) => {
  const { content } = props;
  return (
    <Header
      style={{ position: "fixed", zIndex: 10, width: "100%" }}
      className={style.header}
    >
      <FeMade />
      {content()}
    </Header>
  );
};

export default Component;
