import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import ProjectsScreen from "./ProjectsScreen";
import style from "./ProjectsScreen.module.css";

const ProjectsScreenContainer = (props: any) => {
  const { request } = useHttp();
  const message = useMessage();

  const [course, setCourse] = useState<any>(null);

  useEffect(() => {
    fetchCoursesHandler();
  }, []);

  const fetchCoursesHandler = async () => {
    try {
      const data = await request(`${baseUrl}/api/student/courses/`, "GET");
      if (data) {
        let filteredData = data.filter((el: any) => el.status);
        setCourse(filteredData);
      }
    } catch (e) {}
  };

  const fetchCoursesProjectHandler = async (id: any, setProjects: any) => {
    try {
      const data = await request(
        `${baseUrl}/api/student/courses/${id}/projects/`,
        "GET"
      );
      if (data) {
        setProjects(data);
      }
    } catch (e) {}
  };

  if (!course) {
    return (
      <div className={style.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <ProjectsScreen
      coursesProjects={course}
      fetchCoursesProjectHandler={fetchCoursesProjectHandler}
      {...props}
    />
  );
};

export default ProjectsScreenContainer;
