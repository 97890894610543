import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import StudentsScreen from "./StudentsScreen";

const StudentsScreenContainer = (props: any) => {
  const [studentList, setStudentList] = useState<any>([]);
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();

  useEffect(() => {
    message(errorMessage, true);
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error?.detail) {
      message(error?.detail, true);
    }
    clearError();
  }, [error, message, clearError]);

  useEffect(() => {
    getStudentList();
  }, []);

  const getStudentList = async () => {
    try {
      const req = await request(`${baseUrl}/api/mentor/student-list/`, "GET");
      if (req) {
        setStudentList(req);
        
      }
    } catch (e) {}
  };

  const setStudentProjectStatus = async (id: string, completed: boolean) => {
    const data = {
      completed,
    };
    try {
      const req = await request(
        `${baseUrl}/api/mentor/project-progress/${id}/`,
        "PATCH",
        data
      );
      if (req) {
        const newArr = studentList?.map((el: any) => {
          const project = el?.project?.map((proj: any) => {
            if (proj?.id === id) {
              return { ...proj, completed: completed };
            }
            return proj;
          });
          return { ...el, project };
        });
        setStudentList(newArr);
      }
    } catch (e) {}
  };

  return (
    <StudentsScreen
      loading={loading}
      setStudentProjectStatus={setStudentProjectStatus}
      studentList={studentList}
      {...props}
    />
  );
};

export default StudentsScreenContainer;
