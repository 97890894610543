import React, { useContext, useEffect } from "react";
import { AuthorizationContext } from "../../../store/context/authorizationContext";
import { CoursesContext } from "../../../store/context/coursesContext";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import CoursesScreen from "./CoursesScreen";

const CoursesScreenContainer = (props: any) => {
  const { loading, request, errorMessage, clearError } = useHttp();
  const message = useMessage();

  const { setCourses } = useContext(CoursesContext);
  const { isMentor } = useContext(AuthorizationContext);

  useEffect(() => {
    message(errorMessage, true);
    clearError();
  }, [errorMessage, message, clearError]);

  useEffect(() => {
    fetchCoursesHandler();
  }, []);

  const fetchCoursesHandler = async () => {
    try {
      const data = await request(
        `${baseUrl}/api/${isMentor ? "mentor" : "student"}/courses/`,
        "GET"
      );
      setCourses(data);
    } catch (e) {}
  };

  return <CoursesScreen loading={loading} {...props} />;
};

export default CoursesScreenContainer;
