import { useCallback } from "react";
import { notification } from "antd";

notification.config({
  placement: "bottomRight",
  rtl: false,
  closeIcon: () => null,
});

const useMessage = () => {
  return useCallback((text, error) => {
    if (notification && text) {
      if(error){
        notification.error({
          message: text,
        });
      } else {
        notification.success({
          message: text,
        });
      }
    }
  }, []);
};

export default useMessage;
