import React, { FC, ReactElement, useContext, useEffect } from "react";
import HeaderContainer from "../components/Header/HeaderContainer";
import { Layout, Spin } from "antd";
import AuthRoutes from "./public/AuthRoutes";
import SecureRoutes from "./secure/SecureRoutes";
import { AuthorizationContext } from "../store/context/authorizationContext";
import style from "./Navigation.module.css";
import "antd/dist/antd.css";

const Component: FC<any> = (props): ReactElement => {
  const { isLogin, ready, getWebinarList } = useContext(AuthorizationContext);

  useEffect(() => {
    getWebinarList();
  }, [isLogin]);

  if (!ready) {
    return (
      <div className={style.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout>
      <HeaderContainer />
      {isLogin ? (
        <SecureRoutes />
      ) : (
        <AuthRoutes />
      )}
    </Layout>
  );
};

export default Component;
