import React from 'react'
import style from './CourseLectures.module.css'
import parse from 'html-react-parser'
import 'antd/dist/antd.css'
import { Button } from 'antd'
import ReactPlayer from 'react-player'
import { FileTextFilled, FilePdfFilled } from '@ant-design/icons'
import Modal from 'react-modal'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

const Component = (props: any) => {
  const {
    lectures,
    selecterdLecture,
    loading,
    setSelecterdLecture,
    fetchHelpHandler,
    fetchCompeleteLecture,
  } = props

  const currentLection = lectures?.find((el: any) => el.id === selecterdLecture)

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    document.body.classList.add('scroll-off')
    setIsOpen(true)
  }

  function closeModal() {
    document.body.classList.remove('scroll-off')
    setIsOpen(false)
  }

  const customStyles = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 999,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      borderRadius: 24,
      padding: 80,
      paddingLeft: 130,
      paddingRight: 130,
    },
  }

  const ValidateSchema = Yup.object().shape({
    lecture: Yup.string().required('Заповніть поле'),
    text: Yup.string().required('Заповніть поле'),
  })

  const initialValues = {
    lecture: '',
    text: '',
  }

  return (
    <div className={style.courseBody}>
      <div className={style.lecturesBlock}>
        <div className={style.leftColumn}>
          <ReactPlayer
            controls={true}
            url={currentLection?.url}
            width={676}
            height={424}
          />
          <h1 className={style.lectureTitle}>{currentLection?.title}</h1>
          <div className={style.lectureDescription}>
            {parse(currentLection?.description || '')}
          </div>
          <h1 className={style.lectureTitle}>{'Матеріали лекції'}</h1>
          <div className={style.lectureDescription}>
            {parse(currentLection?.files_description || '')}
            {currentLection?.file?.map((el: any) => {
              return (
                <a
                  key={el.id}
                  className={style.examLink}
                  target={'_blank'}
                  rel="noreferrer"
                  href={el?.file}
                >
                  <FilePdfFilled style={{ fontSize: 54, marginRight: 15 }} />
                  <div>{el?.filename || el?.file?.match(/.*\/(.*)$/)[1]}</div>
                </a>
              )
            })}
          </div>
          {currentLection?.with_exam && (
            <>
              <h1 className={style.lectureTitle}>{'Практичне завдання'}</h1>
              <div className={style.lectureDescription}>
                {parse(currentLection?.exam_description || '')}
              </div>
              <a
                target={'_blank'}
                rel="noreferrer"
                href={currentLection?.exam_url}
                className={style.examLink}
              >
                <FileTextFilled style={{ fontSize: 54, marginRight: 15 }} />
                Пройти практичне завдання
              </a>
            </>
          )}
          <h1 className={style.lectureTitle}>{'Менторство'}</h1>
          <div className={style.lectureDescription}>
            Ви можете поставити будь-яке запитання щодо лекції нашому викладачеві і він
            відповість вам у найкоротші терміни!
          </div>
          <div>
            <Button
              onClick={openModal}
              disabled={loading}
              loading={loading}
              className={style.submitBtn}
              htmlType="submit"
              type="primary"
            >
              Запитати
            </Button>
          </div>
        </div>
        <div className={style.rightColumn}>
          {lectures?.map((el: any) => {
            const youtube_parser = (url: any) => {
              if (url) {
                var regExp =
                  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                var match = url.match(regExp)
                return match && match[7].length == 11 ? match[7] : false
              }
            }
            return (
              <div
                onClick={() => {
                  fetchCompeleteLecture(selecterdLecture)
                  setSelecterdLecture(el.id)
                }}
                style={
                  selecterdLecture === el.id
                    ? {
                        backgroundColor: '#F8F8F8',
                      }
                    : {}
                }
                className={style.lectureItem}
                key={el.id}
              >
                <img
                  style={{ width: 128, minWidth: 128, height: 85 }}
                  src={`https://img.youtube.com/vi/${youtube_parser(
                    el.url,
                  )}/maxresdefault.jpg`}
                  alt=""
                />
                <div className={style.lectureItemTitle}>{el.title}</div>
              </div>
            )
          })}
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className={style.modalBody}>
          <h1 className={style.modalTitle}>{'Менторство'}</h1>
          <div className={style.modalDescription}>
            Ви можете поставити будь-яке запитання щодо лекції нашому викладачеві і він
            відповість вам у найкоротші терміни!
          </div>
          <Formik
            validateOnChange={false}
            validationSchema={ValidateSchema}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              fetchHelpHandler(values, closeModal)
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form className={style.form}>
                <label className={style.label} htmlFor="lecture">
                  Оберіть лекцію
                </label>
                <Field
                  className={[style.input].join(' ')}
                  value={values.lecture}
                  as="select"
                  placeholder=""
                  name="lecture"
                >
                  <option defaultValue={''}></option>
                  {lectures.map((el: any) => {
                    const truncate = (input: string) =>
                      input.length > 5 ? `${input.substring(0, 60)}...` : input

                    return <option value={el.id}>{truncate(el.title)}</option>
                  })}
                </Field>
                {errors.lecture && touched.lecture ? (
                  <div className="formError">{errors.lecture}</div>
                ) : null}
                <div style={{ marginTop: 25 }}>
                  <label className={style.label} htmlFor="text">
                    Ваше запитання
                  </label>
                  <Field
                    className={[style.input, style.textarea].join(' ')}
                    id="text"
                    as="textarea"
                    type="text"
                    name="text"
                    placeholder=""
                  />
                  {errors.text && touched.text ? (
                    <div className="formError">{errors.text}</div>
                  ) : null}
                </div>
                <div>
                  <Button
                    disabled={loading}
                    loading={loading}
                    className={style.submitBtn}
                    htmlType="submit"
                    type="primary"
                  >
                    Запитати
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  )
}

export default Component
