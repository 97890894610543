import React from "react";
import style from "./CoursePreview.module.css";
import {
  ClockCircleFilled,
  LaptopOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import parse from "html-react-parser";
import "antd/dist/antd.css";
import { Button } from "antd";

const Component = (props: any) => {
  const { course, loading, fetchRegisterToCourse, status, setStatus } = props;

  let lectureCount = (count: number) => {
    if (count === 0) {
      return "лекцій";
    }
    if (count === 1) {
      return "лекція";
    }
    if (count > 1 && count <= 4) {
      return "лекції";
    }
    if (count > 4) {
      return "лекцій";
    }
    return "0 лекцій";
  };

  let projectsCount = (count: number) => {
    if (count === 0) {
      return "контрольних проектів";
    }
    if (count === 1) {
      return "контрольний проект";
    }
    if (count > 1 && count <= 4) {
      return "контрольні проекти";
    }
    if (count > 4) {
      return "контрольних проектів";
    }
    return "0 контрольних проектів";
  };

  return (
    <div className={style.courseBody}>
      <div className={style.courseInfoContainer}>
        <h3 className={style.courseItemTitle}>{"Цей курс включає:"}</h3>
        <div className={style.courseItemInfo}>
          <div className={style.infoItem}>
            <ClockCircleFilled style={{ fontSize: 28 }} />
            <span>Відеоматеріали</span>
          </div>
          <div className={style.infoItem}>
            <LaptopOutlined style={{ fontSize: 28 }} />
            <span>
              {course?.lecture?.length}{" "}
              {lectureCount(course?.lecture?.length || null)}
            </span>
          </div>
          <div className={style.infoItem}>
            <CheckCircleFilled style={{ fontSize: 28 }} />
            <span>
              {course?.project?.length}{" "}
              {projectsCount(course?.project?.length || null)}
            </span>
          </div>
        </div>
      </div>
      <div className={style.descriptionBody}>
        <h1>Чого ви навчитеся</h1>
        <div className={style.descriptionBlock}>
          {parse(course?.purpose || "")}
        </div>
        <h1>Опис</h1>
        <div className={style.descriptionBlock}>
          {parse(course?.detailed_description || "")}
        </div>
        {course?.lecture?.length !== 0 && (
          <>
            <h1>Контент</h1>
            <div className={style.descriptionBlock}>
              <ul className={style.projList}>
                {course?.lecture?.map((el: any) => (
                  <li>{el.title}</li>
                ))}
              </ul>
            </div>
          </>
        )}
        {course?.project?.length !== 0 && (
          <>
            <h1>Проекти</h1>
            <div className={style.descriptionBlock}>
              <ul className={style.projList}>
                {course?.project?.map((el: any) => (
                  <li>{el.title}</li>
                ))}
              </ul>
            </div>
          </>
        )}
        <div>
          <Button
            onClick={() =>
              !course?.status
                ? fetchRegisterToCourse(course.id)
                : setStatus(!status)
            }
            disabled={loading}
            loading={loading}
            className={style.submitBtn}
            htmlType="submit"
            type="primary"
          >
            {!status
              ? !course?.status
                ? "Почати Курс"
                : "Повернутися до лекцій"
              : "Інформація про курс"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Component;
