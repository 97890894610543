import React, { useContext, useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { NavLink } from "react-router-dom";
import style from "./Header.module.css";
import { DownOutlined } from "@ant-design/icons";
import { AuthorizationContext } from "../../store/context/authorizationContext";
import Header from "./Header";
import "antd/dist/antd.css";

const HeaderContainer = () => {
  const { isMentor, isLogin, logout, userName, getUserHandler, webinaresList } =
    useContext(AuthorizationContext);

  useEffect(() => {
    if (isLogin) {
      getUserHandler();
    }
  }, [isLogin]);

  const menu = (
    <Menu>
      <Menu.Item key={"Вийти"}>
        <div onClick={logout}>Вийти</div>
      </Menu.Item>
    </Menu>
  );

  let content = () => {
    if (!isLogin) {
      return (
        <div className={style.authNav}>
          <NavLink
            className={({ isActive }) => {
              return `${style.navItem} ${
                isActive ? style.navItemSelected : ""
              }`;
            }}
            to="/login"
          >
            Увійти
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              return `${style.navItem} ${
                isActive ? style.navItemSelected : ""
              }`;
            }}
            to="/register"
          >
            Зареєструватись
          </NavLink>
        </div>
      );
    }
    if (!isMentor) {
      return (
        <>
          <div className={style.authNav}>
            <NavLink
              className={({ isActive }) => {
                return `${style.navItemLogin} ${
                  isActive ? style.navItemLoginSelected : ""
                }`;
              }}
              to="/courses"
            >
              Мої курси
            </NavLink>
            <NavLink
              className={({ isActive }) => {
                return `${style.navItemLogin} ${
                  isActive ? style.navItemLoginSelected : ""
                }`;
              }}
              to="/projects"
            >
              Мої проекти
            </NavLink>
            {webinaresList && webinaresList?.length !== 0 && (
              <NavLink
                className={({ isActive }) => {
                  return `${style.navItemLogin} ${
                    isActive ? style.navItemLoginSelected : ""
                  }`;
                }}
                to="/webinars"
              >
                Вебінари
              </NavLink>
            )}
          </div>
          <Dropdown overlay={menu}>
            <div className={style.userName}>
              <span style={{ marginRight: 10 }}>{userName}</span>
              <DownOutlined style={{ fontSize: 14 }} />
            </div>
          </Dropdown>
        </>
      );
    }
    return (
      <>
        <div className={style.authNav}>
          <NavLink
            className={({ isActive }) => {
              return `${style.navItemLogin} ${
                isActive ? style.navItemLoginSelected : ""
              }`;
            }}
            to="/mentor/courses"
          >
            Курси
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              return `${style.navItemLogin} ${
                isActive ? style.navItemLoginSelected : ""
              }`;
            }}
            to="/mentor/students"
          >
            Студенти
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              return `${style.navItemLogin} ${
                isActive ? style.navItemLoginSelected : ""
              }`;
            }}
            to="/mentor/webinars"
          >
            Вебінари
          </NavLink>
        </div>
        <Dropdown overlay={menu}>
          <div className={style.userName}>
            <span style={{ marginRight: 10 }}>{userName}</span>
            <DownOutlined style={{ fontSize: 14 }} />
          </div>
        </Dropdown>
      </>
    );
  };

  return <Header content={content} />;
};

export default HeaderContainer;
