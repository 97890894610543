import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import ResetPassword from "./ResetPassword";

interface data {
  email: string;
  token: string;
  password: string;
  passwordConfirm: string;
}

const ResetPasswordContainer = (props: any) => {
  const navigate = useNavigate();
  const [codeSended, setCodeSended] = useState(false);
  const [codeChecked, setCodeChecked] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();

  useEffect(() => {
    if (errorMessage === "Unexpected end of JSON input") {
      message("Користувач не знайдений", true);
    }
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error) {
      message("Користувач не знайдений", true);
    }
    clearError();
  }, [error, message, clearError]);

  const sendCodeHandler = async (d: data) => {
    const recover = {
      email: d.email,
    };
    try {
      const data = await request(`${baseUrl}/auth/recover/`, "POST", recover);
      if (data) {
        setCodeSended(true);
      }
    } catch (e) {}
  };

  const сheckCodeHandler = async (d: data) => {
    const recover = {
      email: d.email,
      token: d.token,
    };
    try {
      const data = await request(
        `${baseUrl}/auth/recover-code/`,
        "POST",
        recover
      );
      if (data) {
        setCodeChecked(true);
        setAccessToken(data.access);
      }
    } catch (e) {}
  };

  const ressetPasswordHandler = async (d: data) => {
    const recover = {
      password: d.password,
      repeat_password: d.passwordConfirm,
    };
    try {
      const data = await request(`${baseUrl}/auth/reset/`, "POST", recover, {
        Authorization: "Bearer " + accessToken,
      });
      if (data) {
        message(data.message || "Пароль успішно змінено", false);
        return navigate(`/login`);
      }
    } catch (e) {}
  };

  return (
    <ResetPassword
      sendCodeHandler={sendCodeHandler}
      сheckCodeHandler={сheckCodeHandler}
      codeSended={codeSended}
      codeChecked={codeChecked}
      ressetPasswordHandler={ressetPasswordHandler}
      loading={loading}
      {...props}
    />
  );
};

export default ResetPasswordContainer;
