export const timezones = [
  {
    offset: -12,
    text: "UTC-12:00",
  },
  {
    offset: -11,
    text: "UTC-11:00",
  },
  {
    offset: -10,
    text: "UTC-10:00",
  },
  {
    offset: -9,
    text: "UTC-09:00",
  },
  {
    offset: -8,
    text: "UTC-08:00",
  },
  {
    offset: -7,
    text: "UTC-07:00",
  },
  {
    offset: -6,
    text: "UTC-06:00",
  },
  {
    offset: -5,
    text: "UTC-05:00",
  },
  {
    offset: -4.5,
    text: "UTC-04:30",
  },
  {
    offset: -4,
    text: "UTC-04:00",
  },
  {
    offset: -3.5,
    text: "UTC-03:30",
  },
  {
    offset: -3,
    text: "UTC-03:00",
  },
  {
    offset: -2,
    text: "UTC-02:00",
  },
  {
    offset: -1,
    text: "UTC-01:00",
  },
  {
    offset: 0,
    text: "UTC 00:00",
  },
  {
    offset: +1,
    text: "UTC+01:00",
  },
  {
    offset: 2,
    text: "UTC+02:00",
  },
  {
    offset: 3,
    text: "UTC+03:00",
  },
  {
    offset: 4,
    text: "UTC+04:00",
  },
  {
    offset: 5,
    text: "UTC+05:00",
  },
  {
    offset: 6,
    text: "UTC+06:00",
  },
  {
    offset: 7,
    text: "UTC+07:00",
  },
  {
    offset: 8,
    text: "UTC+08:00",
  },
  {
    offset: 9,
    text: "UTC+09:00",
  },
  {
    offset: 10,
    text: "UTC+10:00",
  },
  {
    offset: 11,
    text: "UTC+11:00",
  },
  {
    offset: 12,
    text: "UTC+12:00",
  },
];
