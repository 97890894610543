import React from "react";
import style from "./CourseCreateScreen.module.css";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const Component = (props: any) => {
  const {
    setSelectedTab,
    selectedTab,
    rederTab,
    courseInfo,
    courseImage,
    setCourseImage,
    setCourseImageFile,
    deleteCourseImage,
    courseImageFile
  } = props;
  return (
    <div className={style.container}>
      <div
        style={courseImage ? { backgroundImage: `url('${courseImage}')` } : {}}
        className={style.headerBlock}
      >
        <div className={style.headerBlockTexts}>
          <div className={style.breadCrumb}>
            <Link className={style.breadCrumbLink} to="/mentor/courses">
              Курси
            </Link>
            <span> / {courseInfo?.title || "Новий курс"}</span>
          </div>
          <h1>{courseInfo?.title || "Новий курс"}</h1>
        </div>
        {selectedTab === 0 && (
          <>
            <label htmlFor="upload" className={style.addImage}>
              <EditOutlined style={{ fontSize: 34 }} />
              <input
                style={{ visibility: "hidden", width: 0 }}
                id="upload"
                type="file"
                accept="image/png,image/jpeg"
                onChange={(event: any) => {
                  if (event?.target !== null) {
                    let reader = new FileReader();
                    let file = event.target.files[0];
                    setCourseImageFile(file);
                    reader.onloadend = () => {
                      setCourseImage(reader.result);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </label>
            {courseImage && (
              <div
                onClick={() => {
                  if (courseInfo?.id && !courseImageFile) {
                    deleteCourseImage();
                  } else {
                    setCourseImageFile(null);
                    setCourseImage(null);
                  }
                }}
                style={{ right: 80 }}
                className={style.addImage}
              >
                <DeleteOutlined style={{ fontSize: 34 }} />
              </div>
            )}
          </>
        )}
      </div>
      <div className={style.navigationEdit}>
        <button
          onClick={() => setSelectedTab(0)}
          className={`${style.editBtn} ${
            selectedTab === 0 && style.editBtnСhoosed
          }`}
        >
          Загальна інформація
        </button>
        <button
          onClick={() => {
            if (courseInfo?.lecture && courseInfo?.lecture?.length !== 0) {
              setSelectedTab(1);
            }
          }}
          className={`${style.editBtn} ${
            selectedTab === 1 && style.editBtnСhoosed
          }`}
        >
          Відео матеріали
        </button>
        <button
          onClick={() => {
            if (courseInfo?.project && courseInfo?.project?.length !== 0) {
              setSelectedTab(2);
            }
          }}
          className={`${style.editBtn} ${
            selectedTab === 2 && style.editBtnСhoosed
          }`}
        >
          Проекти
        </button>
      </div>
      {rederTab(selectedTab)}
    </div>
  );
};

export default Component;
