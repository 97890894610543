import React, { createContext, useReducer } from "react";
import * as actions from "./../actions/actionsType";
import { authReducer, initialAuthState } from "./../reducers/authReducer";
import { useAuth, useHttp } from "./../../utils/hooks";
import { baseUrl } from "../../utils/config";

export const AuthorizationContext = createContext<any>(initialAuthState);

const AuthProvider: React.FC<React.ReactNode> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const { request, loading } = useHttp();
  const { token, login, logout, userType, ready } = useAuth();

  const getUserHandler = async () => {
    try {
      const data = await request(`${baseUrl}/auth/profile/`, "GET", null, {
        Authorization: "Bearer " + token,
      });
      if (data) {
        dispatch({
          type: actions.SET_USER_NAME,
          userName: data?.first_name + " " + data?.last_name,
        });
        dispatch({
          type: actions.SET_USER_ID,
          userID: data?.id,
        });
      }
    } catch (e) {}
  };

  const getWebinarList = async () => {
    try {
      const req = await request(
        `${baseUrl}/api/mentor/webinars/`,
        "GET",
        null,
        {
          Authorization: "Bearer " + token,
        }
      );
      if (req) {
        dispatch({
          type: actions.SET_WEBINARS,
          webinaresList: req,
        });
      }
    } catch (e) {}
  };

  const value = {
    isLogin: !!token || state.isLogin,
    loading: loading,
    isMentor: userType === "mentor" || state.isMentor,
    token: token || state.token,
    userName: state.userName,
    userID: state.userID,
    webinaresList: state.webinaresList,
    getWebinarList,
    login,
    logout,
    ready,
    getUserHandler,
  };

  return (
    <AuthorizationContext.Provider value={value}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthProvider;
