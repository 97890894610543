import React from "react";
import AuthorizationContext from "../store/context/authorizationContext";
import Navigation from "./Navigation";

const NavigationContainer = (props: any) => {

  return (
    <AuthorizationContext>
      <Navigation {...props} />
    </AuthorizationContext>
  );
};

export default NavigationContainer;
