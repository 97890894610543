import React, { useState } from 'react'
import style from '../CourseCreateScreen.module.css'
import { Field, Form, Formik } from 'formik'
import JoditEditor from 'jodit-react'
import { YoutubeOutlined, FilePdfFilled, DeleteFilled } from '@ant-design/icons'
import { Button, Checkbox, Spin } from 'antd'
import ReactPlayer from 'react-player'

const Component = (props: any) => {
  const {
    loading,
    initialValuesLection,
    config,
    courseInfo,
    curentLection,
    lectionLoading,
    setCurentLection,
    saveLectureInfo,
    deleteLectionFile,
  } = props

  const [files, setFiles] = useState<any>([])

  const deleteFiles = (file: any) => {
    let newArray = files.filter((e: any) => {
      return e !== file
    })
    setFiles(newArray)
  }

  return (
    <div className={style.formLection}>
      {lectionLoading ? (
        <div
          style={{ backgroundColor: '#ffffff', maxWidth: 675 }}
          className={style.loaderContainer}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValuesLection}
          onSubmit={(values, actions) => {
            values.lecture_file = [...values.lecture_file, ...files]
            setFiles([])
            saveLectureInfo(values)
            actions.setSubmitting(false)
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className={style.leftColumn}>
                {values?.url ? (
                  <ReactPlayer
                    controls={true}
                    url={values?.url}
                    width={676}
                    height={424}
                  />
                ) : (
                  <div className={style.video}>
                    <YoutubeOutlined style={{ fontSize: 92 }} />
                    <span>Додайте посилання на відео знизу</span>
                  </div>
                )}
                <label className={style.label} htmlFor="url">
                  Посилання на відео
                </label>
                <Field
                  className={[style.input].join(' ')}
                  id="url"
                  type="url"
                  name="url"
                  placeholder=""
                />
                <h3 className={style.subTitle}>
                  Лекція {values.number}: {values.title}
                </h3>
                <label
                  className={style.label}
                  style={{ marginBottom: 15 }}
                  htmlFor="description"
                >
                  Опис
                </label>
                <JoditEditor
                  value={values.description}
                  config={config}
                  onChange={newContent => setFieldValue('description', newContent)}
                />
                {errors.description && touched.description ? (
                  <div className="formError">{errors.description}</div>
                ) : null}
                <h3 className={style.subTitle}>Матеріали лекції</h3>
                <label
                  className={style.label}
                  style={{ marginBottom: 15 }}
                  htmlFor="files_description"
                >
                  Опис
                </label>
                <JoditEditor
                  value={values.files_description}
                  config={config}
                  onChange={newContent => setFieldValue('files_description', newContent)}
                />
                {errors.files_description && touched.files_description ? (
                  <div className="formError">{errors.files_description}</div>
                ) : null}
                {values?.lecture_file &&
                  values?.lecture_file?.map((el: any, index: number) => {
                    return (
                      <div key={index} className={style.addedFile}>
                        <FilePdfFilled style={{ fontSize: 64 }} />
                        <div onClick={() => window.open(el?.file, '_blank')}>
                          {el?.filename || el?.file?.match(/.*\/(.*)$/)[1]}
                        </div>
                        <button
                          type="button"
                          className={style.deleteBtn}
                          onClick={() => {
                            const del = window.confirm('Ви дійсно хочете видалити файл?')
                            if (del) {
                              deleteLectionFile(
                                el.id,
                                values?.lecture_file,
                                setFieldValue,
                              )
                            }
                          }}
                        >
                          <DeleteFilled style={{ fontSize: 34, color: 'red' }} />
                        </button>
                      </div>
                    )
                  })}
                {files?.map((el: any, index: number) => {
                  let fileURL = window.URL.createObjectURL(el)
                  return (
                    <div key={index} className={style.addedFile}>
                      <FilePdfFilled style={{ fontSize: 64 }} />
                      <div onClick={() => window.open(fileURL, '_blank')}>{el?.name}</div>
                      <button
                        type="button"
                        onClick={() => {
                          const del = window.confirm('Ви дійсно хочете видалити файл?')
                          if (del) {
                            deleteFiles(el)
                          }
                        }}
                        className={style.deleteBtn}
                      >
                        <DeleteFilled style={{ fontSize: 34, color: 'red' }} />
                      </button>
                    </div>
                  )
                })}
                <label style={{ marginTop: 100 }} htmlFor="docs">
                  <div className={style.addFile}>
                    <FilePdfFilled style={{ fontSize: 64 }} />
                    <div>Завантажити у форматі PDF</div>
                    <input
                      style={{ visibility: 'hidden' }}
                      id="docs"
                      name="docs"
                      type="file"
                      accept=".pdf"
                      onChange={(event: any) => {
                        if (event?.target !== null && event?.target?.files[0]) {
                          setFiles((f: any) => [...f, event?.target?.files[0]])
                        }
                      }}
                    />
                  </div>
                </label>
                <div className={style.checkBoxBlock}>
                  <div className={style.checkBox}>
                    <Checkbox
                      name="with_exam"
                      checked={values.with_exam}
                      onChange={e => setFieldValue('with_exam', e.target.checked)}
                    />
                    <div className={style.practice}>Практичне завдання</div>
                  </div>
                </div>
                {values?.with_exam && (
                  <>
                    <label
                      className={style.label}
                      style={{ marginBottom: 15 }}
                      htmlFor="exam_description"
                    >
                      Опис
                    </label>
                    <Field
                      className={[style.input].join(' ')}
                      id="exam_description"
                      type="exam_description"
                      name="exam_description"
                      placeholder=""
                    />
                    {errors.exam_description && touched.exam_description ? (
                      <div className="formError">{errors.exam_description}</div>
                    ) : null}
                    <label className={style.label} htmlFor="exam_url">
                      Посилання на завдання
                    </label>
                    <Field
                      className={[style.input].join(' ')}
                      id="exam_url"
                      type="exam_url"
                      name="exam_url"
                      placeholder=""
                    />
                  </>
                )}
                <div>
                  <Button
                    disabled={loading}
                    loading={loading}
                    className={style.submitBtn}
                    htmlType="submit"
                    type="primary"
                  >
                    Зберегти
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className={style.rightColumn}>
        {courseInfo?.lecture
          ?.sort((a: any, b: any) => a?.number - b?.number)
          ?.map((el: any) => {
            return (
              <button
                key={el.id}
                style={curentLection === el.id ? { backgroundColor: '#F8F8F8' } : {}}
                className={style.lectureBtn}
                onClick={() => setCurentLection(el.id)}
              >
                Лекція {el.number}: {el.title}
              </button>
            )
          })}
      </div>
    </div>
  )
}

export default Component
