import React, { useContext } from "react";
import style from "./CoursesScreen.module.css";
import image from "./../../../assets/images/bg3.png";
import {
  ClockCircleFilled,
  LaptopOutlined,
  CheckCircleFilled,
  TeamOutlined,
} from "@ant-design/icons";
import { AuthorizationContext } from "../../../store/context/authorizationContext";
import { CoursesContext } from "../../../store/context/coursesContext";
import { Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";

const Component = (props: any) => {
  const { loading } = props;
  const { isMentor } = useContext(AuthorizationContext);
  const { coursesList } = useContext(CoursesContext);
  const navigate = useNavigate();

  let lectureCount = (count: number) => {
    if (count === 0) {
      return "лекцій";
    }
    if (count === 1) {
      return "лекція";
    }
    if (count > 1 && count <= 4) {
      return "лекції";
    }
    if (count > 4) {
      return "лекцій";
    }
    return "0 лекцій";
  };

  let projectsCount = (count: number) => {
    if (count === 0) {
      return "контрольних проектів";
    }
    if (count === 1) {
      return "контрольний проект";
    }
    if (count > 1 && count <= 4) {
      return "контрольні проекти";
    }
    if (count > 4) {
      return "контрольних проектів";
    }
    return "0 контрольних проектів";
  };

  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <div className={style.headerBlockTexts}>
          <h1>Мої курси</h1>
          <h2>
            Навички для вашого сьогодення і вашого майбутнього. Почніть з нами.
          </h2>
        </div>
      </div>
      <div className={style.coursesBlock}>
        {loading && (
          <div className={style.loaderContainer}>
            <Spin size="large" />
          </div>
        )}
        {!isMentor &&
          coursesList?.map((el: any) => {
            if (el?.lecture?.length === 0) {
              return null;
            }
            return (
              <div
                onClick={() => navigate(`/course/${el.id}`)}
                className={[style.courseItem, style.courseItemStudent].join(
                  " "
                )}
                key={el.id}
              >
                <img
                  className={style.courseImage}
                  src={el.image || image}
                  alt="Зображення курсу"
                />
                <div className={style.courseItemInfoBlock}>
                  <h3 className={style.courseItemTitle}>{el?.title}</h3>

                  <div className={style.courseItemInfo}>
                    <div className={style.infoItem}>
                      <ClockCircleFilled style={{ fontSize: 28 }} />
                      <span>Відеоматеріали</span>
                    </div>
                    <div className={style.infoItem}>
                      <LaptopOutlined style={{ fontSize: 28 }} />
                      <span>
                        {el?.lecture?.length}{" "}
                        {lectureCount(el?.lecture?.length || null)}
                      </span>
                    </div>
                    <div className={style.infoItem}>
                      <CheckCircleFilled style={{ fontSize: 28 }} />
                      <span>
                        {el?.project?.length}{" "}
                        {projectsCount(el?.project?.length || null)}
                      </span>
                    </div>
                  </div>
                  {el.status && (
                    <div className={style.courseItemInfo}>
                      <div className={style.progressBlock}>
                        <div>
                          {(el?.progress?.lecture_completed * 100) /
                            el?.progress?.lecture_total ===
                          100
                            ? "Готово"
                            : "У процесі"}
                          ,{" "}
                          {(
                            (el?.progress?.lecture_completed * 100) /
                            el?.progress?.lecture_total
                          ).toFixed(0)}
                          % лекцій переглянуто
                        </div>
                        <div className={style.tableProgressBar}>
                          <div
                            style={{
                              width: `${(
                                (el?.progress?.lecture_completed * 100) /
                                el?.progress?.lecture_total
                              ).toFixed(0)}%`,
                            }}
                            className={style.tableProgressBarInside}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {isMentor &&
          coursesList?.map((el: any) => {
            return (
              <div className={style.courseItem} key={el.id}>
                <img
                  className={style.courseImage}
                  src={el.image || image}
                  alt="Зображення курсу"
                />
                <div className={style.courseItemInfoBlock}>
                  <h3 className={style.courseItemTitle}>{el?.title}</h3>
                  <div className={style.courseItemInfo}>
                    <div className={style.infoItem}>
                      <TeamOutlined style={{ fontSize: 28 }} />
                      <span>Кількість студентів: {el.students}</span>
                    </div>
                  </div>
                </div>
                <Link
                  // to="/mentor/courses/create"
                  to={`/mentor/courses/create/${el.id}`}
                  className={style.submitBtn}
                  type="primary"
                >
                  Редагувати
                </Link>
              </div>
            );
          })}
        {isMentor && coursesList?.length === 0 && (
          <div className={style.courseItem}>
            <img
              className={style.courseImage}
              src={image}
              alt="Зображення курсу"
            />
            <div className={style.courseItemInfoBlock}>
              <h3 className={style.courseItemTitle}>Новий курс</h3>
              <div className={style.courseItemInfo}>
                <div className={style.infoItem} style={{ flexBasis: "100%" }}>
                  <span style={{ fontWeight: "normal" }}>
                    Додайте необхідний опис та всі відео матеріали.
                  </span>
                </div>
              </div>
            </div>
            <Link
              to="/mentor/courses/create"
              className={style.submitBtn}
              type="primary"
            >
              Створити
            </Link>
          </div>
        )}
        {isMentor && coursesList?.length > 0 && (
          <div className={style.newCourseBlock}>
            <Link
              to="/mentor/courses/create"
              className={style.newCourse}
              type="primary"
            >
              Новий курс
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Component;
