import { useState, useCallback, useEffect } from "react";

const userData = "userData";
const userCreds = "userCreds";

const useAuth = () => {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const [userType, setUserType] = useState(null);

  const login = useCallback((jwtToken, type, data) => {
    setToken(jwtToken);
    setUserType(type);

    localStorage.setItem(
      userData,
      JSON.stringify({
        userType: type,
        token: jwtToken,
      })
    );

    if (data?.saved) {
      localStorage.setItem(
        userCreds,
        JSON.stringify({
          email: data.email,
          password: data.password,
          saved: data.saved,
        })
      );
    } else {
      localStorage.removeItem(userCreds);
    }
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserType(null);
    localStorage.removeItem(userData);
  }, []);

  useEffect(() => {
    let user = localStorage.getItem(userData);
    let userCred = localStorage.getItem(userCreds);
    if (user !== null) {
      const userInfo = JSON.parse(user);
      let userSavedData;
      if (userCred !== null) {
        userSavedData = JSON.parse(userCred);
      }
      if (userInfo && userInfo.token) {
        login(userInfo.token, userInfo.userType, userSavedData);
      }
    }
    setReady(true);
  }, [login]);

  return { login, logout, token, userType, ready };
};

export default useAuth;
