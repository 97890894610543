import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "antd/dist/antd.css";
import { Layout } from "antd";
import {
  LoginScreen,
  RegisterScreen,
  ResetPassword,
  TermsScreen,
} from "../../screens";
import style from "../Navigation.module.css";

const Component = (props: any) => {
  const { Content } = Layout;
  return (
    <Content className={style.containerPublic}>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />\
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/terms" element={<TermsScreen />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </Content>
  );
};

export default Component;
