import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthorizationContext } from "../../../store/context/authorizationContext";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import WebinaresCreateScreen from "./WebinaresCreateScreen";

const WebinaresScreenContainer = (props: any) => {
  const [webinarLoading, setWebinarLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [webinarImg, setWebinarImg] = useState(null);
  const [webinarImgFile, setWebinarImgFile] = useState(null);
  const [curentWebinar, setCurentWebinar] = useState<any>(null);

  const { getWebinarList } = useContext(AuthorizationContext);

  const navigate = useNavigate();
  const params = useParams();

  const [initialValuesWebinar, setInitialValuesWebinar] = useState({
    id: "",
    date: "",
    url: "",
    title: "",
    description: "",
    speaker: "",
    announcement: false,
    zoom_url: "",
    course: "",
    start_time: "",
    end_time: "1",
    timezone: "",
  });

  useEffect(() => {
    fetchCoursesHandler();
  }, []);

  useEffect(() => {
    if (curentWebinar) {
      let initialValues: any = {
        id: curentWebinar.id,
        date: curentWebinar.date,
        url: curentWebinar.url,
        title: curentWebinar.title,
        description: curentWebinar.description,
        speaker: curentWebinar.speaker,
        announcement: curentWebinar.announcement,
        zoom_url: curentWebinar.zoom_url,
        course: curentWebinar.course,
        start_time: curentWebinar.start_time,
        end_time: curentWebinar.end_time,
        timezone: curentWebinar.timezone,
      };
      setWebinarImg(curentWebinar?.image);
      setInitialValuesWebinar(initialValues);
    }
  }, [curentWebinar]);

  useEffect(() => {
    if (params?.id) {
      getWebinar(params?.id);
    } else {
      setCurentWebinar({});
    }
  }, []);

  const getWebinar = async (id: string) => {
    setWebinarLoading(true);
    try {
      const req = await request(`${baseUrl}/api/mentor/webinars/${id}/`, "GET");
      if (req) {
        setCurentWebinar(req);
        setWebinarLoading(false);
      }
    } catch (e) {}
  };

  const fetchCoursesHandler = async () => {
    try {
      const data = await request(`${baseUrl}/api/mentor/courses/`, "GET");
      setCourses(data);
    } catch (e) {}
  };

  const createWebinarHandler = async (values: any) => {
    if (params?.id) {
      try {
        const data = await request(
          `${baseUrl}/api/mentor/webinars/${params?.id}/`,
          "PATCH",
          values
        );
        if (data) {
          saveWebinareImage(params?.id);
          message("Вебінар збереженно", false);
          return navigate(`/mentor/webinars`);
        }
      } catch (e) {}
    } else {
      try {
        const data = await request(
          `${baseUrl}/api/mentor/webinars/`,
          "POST",
          values
        );
        if (data) {
          saveWebinareImage(data.id);
          message("Вебінар збереженно", false);
          getWebinarList();
          return navigate(`/mentor/webinars`);
        }
      } catch (e) {}
    }
  };

  const saveWebinareImage = async (id: string) => {
    if (webinarImgFile && id) {
      var d = new FormData();
      d.append("image", webinarImgFile);
      try {
        const r = await request(
          `${baseUrl}/api/mentor/webinars/${id}/image/`,
          "PATCH",
          d,
          {},
          true
        );
        if (r) {
        }
      } catch (e) {}
    }
  };

  const deleteWebinareImage = async (id: string) => {
    if (webinarImg && id && !webinarImgFile) {
      try {
        const r = await request(
          `${baseUrl}/api/mentor/webinars/${id}/image/`,
          "DELETE",
          null
        );
        if (r) {
          setWebinarImgFile(null);
          setWebinarImg(null);
        }
      } catch (e) {}
    } else {
      setWebinarImgFile(null);
      setWebinarImg(null);
    }
  };

  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();

  useEffect(() => {
    message(errorMessage, true);
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error?.detail) {
      message(error?.detail, true);
    }
    clearError();
  }, [error, message, clearError]);

  return (
    <WebinaresCreateScreen
      loading={loading}
      courses={courses}
      webinarLoading={webinarLoading}
      initialValuesWebinar={initialValuesWebinar}
      createWebinarHandler={createWebinarHandler}
      webinarImg={webinarImg}
      setWebinarImgFile={setWebinarImgFile}
      setWebinarImg={setWebinarImg}
      deleteWebinareImage={deleteWebinareImage}
      {...props}
    />
  );
};

export default WebinaresScreenContainer;
