import { Field, Form, Formik } from "formik";
import { Button } from "antd";
import { EyeOutlined, EyeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import style from "./RegisterScreen.module.css";
import * as Yup from "yup";

interface MyFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
}

const initialValues: MyFormValues = {
  email: "",
  password: "",
  passwordConfirm: "",
  firstName: "",
  lastName: "",
};

const Component = (props: any) => {
  const { loading, registerHandler } = props;

  const [showPass, setIsShowPass] = useState(false);
  const [showResetPass, setIsShowResetPass] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Недійсна пошта").required("Заповніть поле"),
    password: Yup.string()
      .trim()
      .min(
        8,
        ({ min }) => `Пароль повинен містити ${min} символів без пробілів`
      )
      .required("Заповніть поле"),
    passwordConfirm: Yup.string()
      .trim()
      .oneOf([Yup.ref("password"), null], "Паролі не співпадають")
      .required("Заповніть поле"),
    firstName: Yup.string()
      .min(2, "Занадто коротке іи'я")
      .max(70, "Занадто довге іи'я")
      .required("Заповніть поле"),
    lastName: Yup.string()
      .min(2, "Занадто коротке призвище")
      .max(70, "Занадто довге призвище")
      .required("Заповніть поле"),
  });

  return (
    <div className={style.container}>
      <h1 className={style.title}>Зареєструйтеся та почніть вчитися!</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={RegisterSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          const data = {
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            email: values.email,
          };
          registerHandler(data);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form className={style.form}>
            <label className={style.label} htmlFor="firstName">
              Ім'я
            </label>
            <Field
              className={[
                style.input,
                errors.firstName && touched.firstName && style.inputError,
              ].join(" ")}
              id="firstName"
              type="text"
              name="firstName"
              placeholder=""
            />
            {errors.firstName && touched.firstName ? (
              <div className="formError">{errors.firstName}</div>
            ) : null}
            <label className={style.label} htmlFor="lastName">
              Прізвище
            </label>
            <Field
              className={[
                style.input,
                errors.lastName && touched.lastName && style.inputError,
              ].join(" ")}
              id="lastName"
              type="text"
              name="lastName"
              placeholder=""
            />
            {errors.lastName && touched.lastName ? (
              <div className="formError">{errors.lastName}</div>
            ) : null}
            <label className={style.label} htmlFor="email">
              Пошта
            </label>
            <Field
              className={[
                style.input,
                errors.email && touched.email && style.inputError,
              ].join(" ")}
              id="email"
              type="email"
              name="email"
              placeholder=""
            />
            {errors.email && touched.email ? (
              <div className="formError">{errors.email}</div>
            ) : null}
            <label className={style.label} htmlFor="password">
              Пароль
            </label>
            <div className={style.passwordBlock}>
              <Field
                className={[
                  style.input,
                  errors.password && touched.password && style.inputError,
                ].join(" ")}
                type={showPass ? "text" : "password"}
                id="password"
                name="password"
                placeholder=""
              />
              {showPass ? (
                <EyeFilled
                  style={{ fontSize: 28 }}
                  onClick={() => {
                    setIsShowPass(!showPass);
                  }}
                  className={style.showPassIcon}
                />
              ) : (
                <EyeOutlined
                  onClick={() => {
                    setIsShowPass(!showPass);
                  }}
                  style={{ fontSize: 28 }}
                  className={style.showPassIcon}
                />
              )}
            </div>
            {errors.password && touched.password ? (
              <div className="formError">{errors.password}</div>
            ) : null}
            <label className={style.label} htmlFor="passwordConfirm">
              Підтвердити пароль
            </label>
            <div className={style.passwordBlock}>
              <Field
                className={[
                  style.input,
                  errors.passwordConfirm &&
                    touched.passwordConfirm &&
                    style.inputError,
                ].join(" ")}
                type={showResetPass ? "text" : "password"}
                id="passwordConfirm"
                name="passwordConfirm"
                placeholder=""
              />
              {showResetPass ? (
                <EyeFilled
                  style={{ fontSize: 28 }}
                  onClick={() => {
                    setIsShowResetPass(!showResetPass);
                  }}
                  className={style.showPassIcon}
                />
              ) : (
                <EyeOutlined
                  onClick={() => {
                    setIsShowResetPass(!showResetPass);
                  }}
                  style={{ fontSize: 28 }}
                  className={style.showPassIcon}
                />
              )}
            </div>
            {errors.passwordConfirm && touched.passwordConfirm ? (
              <div className="formError">{errors.passwordConfirm}</div>
            ) : null}
            <Button
              disabled={loading}
              loading={loading}
              className={style.submitBtn}
              htmlType="submit"
              type="primary"
            >
              Створити Акаунт
            </Button>
          </Form>
        )}
      </Formik>
      <div className={style.terms}>
        <div style={{ maxWidth: 235 }}>
          By using Femade you agree to our{" "}
          <Link target="_blank" to="/terms">
            <b style={{ color: "#a548ff" }}>Terms of use</b>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Component;
