import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import RegisterScreen from "./RegisterScreen";

interface data {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
}

const RegisterScreenContainer = (props: any) => {
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const navigate = useNavigate();
  const message = useMessage();

  useEffect(() => {
    message(errorMessage, true);
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error?.email) {
      if (error.email[0] === "This field must be unique.") {
        message("Такий користувач вже зареєстрований", true);
      } else {
        message(error.email[0], true);
      }
    }
    clearError();
  }, [error, message, clearError]);

  const registerHandler = async (d: data) => {
    try {
      const data = await request(`${baseUrl}/auth/register/`, "POST", d);
      message(data.message || "Ви успішно зареєструвалися", false);
      if (data) {
        return navigate(`/login`);
      }
    } catch (e) {}
  };
  return (
    <RegisterScreen
      loading={loading}
      registerHandler={registerHandler}
      {...props}
    />
  );
};

export default RegisterScreenContainer;
