import React, { useEffect, useState } from "react";
import style from "./CourseOpenScreen.module.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Button } from "antd";
import CoursePreview from "../../../components/CoursePreview/CoursePreview";
import CourseLectures from "../../../components/CourseLectures/CourseLectures";

const Component = (props: any) => {
  const { course, loading, fetchRegisterToCourse, lectures } = props;

  const [status, setStatus] = useState(course?.status);

  useEffect(() => {
    setStatus(course?.status);
  }, [course]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [status]);

  return (
    <div className={style.container}>
      <div
        style={
          course?.image ? { backgroundImage: `url('${course?.image}')` } : {}
        }
        className={style.headerBlock}
      >
        <div className={style.headerBlockTexts}>
          <div className={style.breadCrumb}>
            <Link className={style.breadCrumbLink} to="/mentor/courses">
              Курси
            </Link>
            <span> / {course?.title || "Новий курс"}</span>
          </div>
          <h1>{course?.title || "Новий курс"}</h1>

          {!status && (
            <h2>{parse(course?.description || "") || "Новий курс"}</h2>
          )}
          <Button
            onClick={() =>
              !course?.status
                ? fetchRegisterToCourse(course.id)
                : setStatus(!status)
            }
            disabled={loading}
            loading={loading}
            className={style.submitBtn}
            htmlType="submit"
            type="primary"
          >
            {!status
              ? !course?.status
                ? "Почати Курс"
                : "Повернутися до лекцій"
              : "Інформація про курс"}
          </Button>
        </div>
      </div>
      {status && lectures ? (
        <CourseLectures {...props} />
      ) : (
        <CoursePreview status={status} setStatus={setStatus} {...props} />
      )}
    </div>
  );
};

export default Component;
