import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Checkbox } from "formik-antd";
import { EyeOutlined, EyeFilled } from "@ant-design/icons";
import { Button } from "antd";
import style from "./LoginScreen.module.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";

let userCred = localStorage.getItem("userCreds");
const parsedUserCred = userCred !== null ? JSON.parse(userCred) : null;

const initialValues: MyFormValues = {
  email: parsedUserCred?.email || "",
  password: parsedUserCred?.password || "",
  saved: parsedUserCred?.saved || false,
};

interface MyFormValues {
  email: string;
  password: string;
  saved: boolean;
}

const Component = (props: any) => {
  const { loading, loginHandler } = props;

  const [showPass, setIsShowPass] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Недійсна пошта").required("Заповніть поле"),
    password: Yup.string().required("Заповніть поле"),
  });

  return (
    <div className={style.container}>
      <h1 className={style.title}>Ласкаво просимо знову! </h1>
      <Formik
        validationSchema={LoginSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          loginHandler(values);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form className={style.form}>
            <label className={style.label} htmlFor="email">
              Пошта
            </label>
            <Field
              className={[
                style.input,
                errors.email && touched.email && style.inputError,
              ].join(" ")}
              id="email"
              type="email"
              name="email"
              placeholder=""
            />
            {errors.email && touched.email ? (
              <div className="formError">{errors.email}</div>
            ) : null}
            <label className={style.label} htmlFor="password">
              Пароль
            </label>
            <div className={style.passwordBlock}>
              <Field
                className={[
                  style.input,
                  errors.password && touched.password && style.inputError,
                ].join(" ")}
                type={showPass ? "text" : "password"}
                id="password"
                name="password"
                placeholder=""
              />
              {showPass ? (
                <EyeFilled
                  style={{ fontSize: 28 }}
                  onClick={() => {
                    setIsShowPass(!showPass);
                  }}
                  className={style.showPassIcon}
                />
              ) : (
                <EyeOutlined
                  onClick={() => {
                    setIsShowPass(!showPass);
                  }}
                  style={{ fontSize: 28 }}
                  className={style.showPassIcon}
                />
              )}
            </div>
            {errors.password && touched.password ? (
              <div className="formError">{errors.password}</div>
            ) : null}
            <div className={style.checkBoxBlock}>
              <div className={style.checkBox}>
                <Checkbox name="saved" />
                <span className={style.checkBoxText}>Запам'ятати мене</span>
              </div>
              <Link className={style.fogotPass} to="/reset_password">
                Забув пароль
              </Link>
            </div>
            <Button
              disabled={loading}
              loading={loading}
              className={style.submitBtn}
              htmlType="submit"
              type="primary"
            >
              Увійти
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Component;
