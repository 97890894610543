import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../../utils/config'
import { useHttp, useMessage } from '../../../utils/hooks'
import CourseCreateScreen from './CourseCreateScreen'
import ProjectsScreen from './tabScreens/ProjectsTab'
import CoursesTab from './tabScreens/CoursesTab'
import LecturesTab from './tabScreens/LecturesTab'
import style from './CourseCreateScreen.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'

const CourseCreateScreenContainer = (props: any) => {
  const { loading, request, error, errorMessage, clearError, clearErrorMessage } =
    useHttp()
  const message = useMessage()
  const params = useParams()
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState(0)
  const [courseImage, setCourseImage] = useState(null)
  const [courseImageFile, setCourseImageFile] = useState(null)
  const [courseInfo, setCourseInfo] = useState<any>(null)
  const [curentLection, setCurentLection] = useState<any>(null)
  const [curentProject, setCurentProject] = useState<any>(null)
  const [lectionLoading, setLectionLoading] = useState<any>(false)
  const [projectLoading, setProjectLoading] = useState<any>(false)
  const [initialValuesCourse, setInitialValuesCourse] = useState({
    title: '',
    description: '',
    purpose: '',
    detailed_description: '',
    lecturesNumber: 1,
    projectsNumber: 1,
  })
  const [initialValuesLection, setInitialValuesLection] = useState({
    id: '',
    number: '',
    url: '',
    title: '',
    description: '',
    lecture_file: [],
    with_exam: false,
    exam_description: '',
    exam_url: '',
  })
  const [initialValuesProject, setInitialValuesProject] = useState({
    id: '',
    number: '',
    url: '',
    title: '',
    description: '',
    lecture_file: [],
    with_exam: false,
    exam_description: '',
    exam_url: '',
  })

  const config = {
    readonly: false,
    buttons: [
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'outdent',
      'indent',
      '|',
      'align',
      'undo',
      'redo',
      '|',
      'hr',
      'eraser',
    ],
    style: {
      zIndex: 0,
      fontSize: 18,
      color: '#3a3a3a',
    },
  }

  useEffect(() => {
    if (courseInfo) {
      let initialValues: any = {
        title: courseInfo?.title,
        description: courseInfo?.description,
        purpose: courseInfo?.purpose,
        detailed_description: courseInfo?.detailed_description,
        lecturesNumber: courseInfo?.lecture?.length,
        projectsNumber: courseInfo?.project?.length,
      }
      courseInfo?.lecture?.forEach((el: any) => {
        initialValues[`lection${el.number}`] = el.title
        initialValues[`lectid${el.number}`] = el.id
      })
      courseInfo?.project?.forEach((el: any) => {
        initialValues[`project${el.number}`] = el.title
        initialValues[`projid${el.number}`] = el.id
      })
      setCourseImage(courseImage || courseInfo?.image)
      setInitialValuesCourse(initialValues)
    }
  }, [selectedTab, courseInfo])

  useEffect(() => {
    if (courseInfo?.lecture && selectedTab === 1 && curentLection) {
      getLectureInfo(curentLection)
    }
  }, [courseInfo, selectedTab, curentLection])

  useEffect(() => {
    if (courseInfo?.project && selectedTab === 2 && curentProject) {
      getProjectInfo(curentProject)
    }
  }, [courseInfo, selectedTab, curentProject])

  const createCourseHandle = async (values: any) => {
    let lectures = []
    let projects = []
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (key.includes('lection')) {
          if (values[key]) {
            lectures.push({
              number: key.replace(/[^\d]/g, ''),
              title: values[key],
            })
          }
        }
        if (key.includes('project')) {
          if (key !== 'projectsNumber') {
            if (values[key]) {
              projects.push({
                number: key.replace(/[^\d]/g, ''),
                title: values[key],
              })
            }
          }
        }
      }
    }
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (key.includes('lectid')) {
          if (values[key]) {
            lectures = lectures.map(el =>
              el.number === key.replace(/[^\d]/g, '') ? { ...el, id: values[key] } : el,
            )
          }
        }
        if (key.includes('projid')) {
          if (values[key]) {
            projects = projects.map(el =>
              el.number === key.replace(/[^\d]/g, '') ? { ...el, id: values[key] } : el,
            )
          }
        }
      }
    }

    let data = {
      description: values.description,
      title: values.title,
      detailed_description: values.detailed_description,
      purpose: values.purpose,
      lecture: lectures,
      project: projects,
    }

    if (courseInfo?.id) {
      try {
        const req = await request(
          `${baseUrl}/api/mentor/courses/${courseInfo?.id}/`,
          'PUT',
          data,
        )
        if (req) {
          message('Курс успішно збереженно', false)
          setCourseInfo(req)
          setCurentLection(
            req?.lecture?.find((e: any) => {
              return e?.number === 1
            })?.id,
          )
          setCurentProject(
            req?.project?.find((e: any) => {
              return e?.number === 1
            })?.id,
          )
          if (req?.lecture?.length !== 0) {
            setSelectedTab(1)
          }
        }
      } catch (e) {}
      return
    }

    try {
      const req = await request(`${baseUrl}/api/mentor/courses/`, 'POST', data)
      if (req) {
        navigate(`/mentor/courses/create/${req?.id}`)
        message('Курс успішно збереженно', false)
        setCourseInfo(req)
        setCurentLection(
          req?.lecture?.find((e: any) => {
            return e?.number === 1
          })?.id,
        )
        setCurentProject(
          req?.project?.find((e: any) => {
            return e?.number === 1
          })?.id,
        )
        if (req?.lecture?.length !== 0) {
          setSelectedTab(1)
        }
      }
    } catch (e) {}
  }

  const saveCourseImage = async () => {
    if (courseImageFile && courseInfo?.id) {
      var d = new FormData()
      d.append('image', courseImageFile)
      try {
        const r = await request(
          `${baseUrl}/api/mentor/courses/${courseInfo?.id}/post-image/`,
          'POST',
          d,
          {},
          true,
        )
        if (r) {
          setCourseImage(r?.image)
        }
      } catch (e) {}
    }
  }

  const deleteCourseImage = async () => {
    if (courseInfo?.id) {
      try {
        const r = await request(
          `${baseUrl}/api/mentor/courses/${courseInfo?.id}/post-image/`,
          'DELETE',
          null,
          {},
        )
        if (r) {
          setCourseImageFile(null)
          setCourseImage(null)
        }
      } catch (e) {}
    }
  }

  const deleteLectionFile = async (id: string, values: any, setFieldValue: any) => {
    if (courseInfo?.id) {
      try {
        const r = await request(`${baseUrl}/api/mentor/file/${id}/`, 'DELETE', null, {})
        if (r) {
          let newArray = values?.filter((e: any) => {
            return e?.id !== id
          })
          setFieldValue('lecture_file', newArray)
        }
      } catch (e) {}
    }
  }

  useEffect(() => {
    saveCourseImage()
  }, [courseInfo])

  useEffect(() => {
    if (params?.id) {
      getCourseInfo(params?.id)
    } else {
      setCourseInfo({})
    }
  }, [])

  const getCourseInfo = async (id: string) => {
    try {
      const req = await request(`${baseUrl}/api/mentor/courses/${id}/`, 'GET')
      if (req) {
        let initialValues: any = {
          title: req?.title,
          description: req?.description,
          purpose: req?.purpose,
          detailed_description: req?.detailed_description,
          lecturesNumber: req?.lecture?.length,
          projectsNumber: req?.project?.length,
        }
        req?.lecture?.forEach((el: any) => {
          initialValues[`lection${el.number}`] = el.title
          initialValues[`lectid${el.number}`] = el.id
        })
        req?.project?.forEach((el: any) => {
          initialValues[`project${el.number}`] = el.title
          initialValues[`projid${el.number}`] = el.id
        })
        setCourseImage(courseInfo?.image)
        setInitialValuesCourse(initialValues)
        setCourseInfo(req)
        setCurentLection(
          req?.lecture?.find((e: any) => {
            return e?.number === 1
          })?.id,
        )
        setCurentProject(
          req?.project?.find((e: any) => {
            return e?.number === 1
          })?.id,
        )
      }
    } catch (e) {}
  }

  const getProjectInfo = async (id: string) => {
    setProjectLoading(true)
    try {
      const req = await request(`${baseUrl}/api/mentor/project/${id}/`, 'GET')
      if (req) {
        let initialValues: any = {
          id: req.id,
          number: req.number,
          url: req.url,
          title: req.title,
          description: req.description,
        }
        setInitialValuesProject(initialValues)
        setProjectLoading(false)
      }
    } catch (e) {}
  }

  const getLectureInfo = async (id: string) => {
    setLectionLoading(true)
    try {
      const req = await request(`${baseUrl}/api/mentor/lecture/${id}/`, 'GET')
      if (req) {
        let initialValues: any = {
          id: req.id,
          number: req.number,
          url: req.url,
          title: req.title,
          description: req.description,
          lecture_file: req.lecture_file,
          files_description: req.files_description,
          with_exam: req.with_exam,
          exam_description: req.exam_description,
          exam_url: req.exam_url,
        }
        setInitialValuesLection(initialValues)
        setLectionLoading(false)
      }
    } catch (e) {}
  }

  const saveProjectInfo = async (values: any) => {
    let data = {
      number: values.number,
      url: values.url,
      title: values.title,
      description: values.description,
    }
    try {
      const req = await request(
        `${baseUrl}/api/mentor/project/${curentProject}/`,
        'PATCH',
        data,
      )
      if (req) {
        message('Проект успішно збереженно', false)
      }
    } catch (e) {}
  }

  const saveLectureInfo = async (values: any) => {
    let data = {
      number: values.number,
      url: values.url,
      title: values.title,
      description: values.description,
      with_exam: values.with_exam,
      files_description: values.files_description,
      exam_description: values.exam_description,
      exam_url: values.exam_url,
    }
    values?.lecture_file.forEach(async (el: any) => {
      if (el?.file) {
        return
      }
      var d = new FormData()
      d.append('file', el)
      try {
        const req = await request(
          `${baseUrl}/api/mentor/lecture/${curentLection}/file/`,
          'POST',
          d,
          {},
          true,
        )
        if (req) {
          getLectureInfo(curentLection)
        }
      } catch (e) {}
    })
    try {
      const req = await request(
        `${baseUrl}/api/mentor/lecture/${curentLection}/`,
        'PATCH',
        data,
      )
      if (req) {
        message('Лекцію успішно збереженно', false)
      }
    } catch (e) {}
  }

  useEffect(() => {
    message(errorMessage, true)
    clearErrorMessage()
  }, [errorMessage, message, clearErrorMessage])

  useEffect(() => {
    if (error?.email) {
      message(error.email[0], true)
    } else if (error?.detail) {
      message(error?.detail, true)
    }
    clearError()
  }, [error, message, clearError])

  const rederTab = (tab: number) => {
    if (tab === 0) {
      return (
        <CoursesTab
          initialValuesCourse={initialValuesCourse}
          createCourseHandle={createCourseHandle}
          lecturesInitial={courseInfo?.lecture?.length || 1}
          config={config}
          loading={loading}
          {...props}
        />
      )
    }
    if (tab === 1) {
      return (
        <LecturesTab
          initialValuesLection={initialValuesLection}
          loading={loading}
          config={config}
          courseInfo={courseInfo}
          curentLection={curentLection}
          lectionLoading={lectionLoading}
          setCurentLection={setCurentLection}
          saveLectureInfo={saveLectureInfo}
          deleteLectionFile={deleteLectionFile}
          {...props}
        />
      )
    }
    if (tab === 2) {
      return (
        <ProjectsScreen
          setCurentProject={setCurentProject}
          initialValuesProject={initialValuesProject}
          projectLoading={projectLoading}
          courseInfo={courseInfo}
          curentProject={curentProject}
          loading={loading}
          saveProjectInfo={saveProjectInfo}
          {...props}
        />
      )
    }
  }

  if (!courseInfo) {
    return (
      <div className={style.loaderContainer}>
        <Spin size="large" />
      </div>
    )
  }

  return (
    <CourseCreateScreen
      selectedTab={selectedTab}
      courseImage={courseImage}
      rederTab={rederTab}
      setSelectedTab={setSelectedTab}
      courseInfo={courseInfo}
      setCourseImage={setCourseImage}
      setCourseImageFile={setCourseImageFile}
      deleteCourseImage={deleteCourseImage}
      courseImageFile={courseImageFile}
      {...props}
    />
  )
}

export default CourseCreateScreenContainer
