import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "antd/dist/antd.css";
import { Layout } from "antd";
import {
  CoursesScreen,
  ProjectsScreen,
  CourseCreateScreen,
  StudentsScreen,
  WebinaresScreen,
  WebinaresCreateScreen,
  CourseOpenScreen,
} from "../../screens";
import CoursesContext from "./../../store/context/coursesContext";
import style from "../Navigation.module.css";
import { AuthorizationContext } from "../../store/context/authorizationContext";

const Component = () => {
  const { Content } = Layout;
  const { isMentor, webinaresList, loading } = useContext(AuthorizationContext);
  return (
    <Content className={style.containerSecure}>
      {isMentor ? (
        <Routes>
          <Route
            path="/mentor/courses"
            element={
              <CoursesContext>
                <CoursesScreen />
              </CoursesContext>
            }
          />
          <Route
            path="/mentor/courses/create"
            element={
              <CoursesContext>
                <CourseCreateScreen />
              </CoursesContext>
            }
          >
            <Route
              path=":id"
              element={
                <CoursesContext>
                  <CourseCreateScreen />
                </CoursesContext>
              }
            />
          </Route>
          <Route path="/mentor/students" element={<StudentsScreen />} />
          <Route
            path="/mentor/webinars"
            element={
              loading ? null : webinaresList?.length !== 0 ? (
                <WebinaresScreen />
              ) : (
                <Navigate replace to="/mentor/webinars/create" />
              )
            }
          />
          <Route
            path="/mentor/webinars/create"
            element={<WebinaresCreateScreen />}
          >
            <Route path=":id" element={<CourseCreateScreen />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/mentor/courses" />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/courses"
            element={
              <CoursesContext>
                <CoursesScreen />
              </CoursesContext>
            }
          />
          <Route
            path="/course"
            element={
              <CoursesContext>
                <CourseOpenScreen />
              </CoursesContext>
            }
          >
            <Route
              path=":id"
              element={
                <CoursesContext>
                  <CourseOpenScreen />
                </CoursesContext>
              }
            />
          </Route>
          <Route path="/projects" element={<ProjectsScreen />} />
          {webinaresList && webinaresList.length !== 0 && (
            <Route path="/webinars" element={<WebinaresScreen />} />
          )}
          <Route path="*" element={<Navigate replace to="/courses" />} />
        </Routes>
      )}
    </Content>
  );
};

export default Component;
