import * as actions from "../actions/actionsType";

export const initialCoursesState = {
  coursesList: null,
};

export const coursesReducer = (state = initialCoursesState, action: any) => {
  switch (action.type) {
    case actions.SET_COURSES_LIST:
      return {
        ...state,
        coursesList: action.coursesList,
      };
    default:
      return state;
  }
};
