import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/index";
import { BrowserRouter } from "react-router-dom";
import NavigationContainer from "./navigation/NavigationContainer";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <NavigationContainer />
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
