import React, { useContext, useEffect } from "react";
import { AuthorizationContext } from "../../../store/context/authorizationContext";
import { baseUrl } from "../../../utils/config";
import { useHttp, useMessage } from "../../../utils/hooks";
import LoginScreen from "./LoginScreen";

interface data {
  password: string;
  email: string;
}

const LoginScreenContainer = (props: any) => {
  const {
    loading,
    request,
    error,
    errorMessage,
    clearError,
    clearErrorMessage,
  } = useHttp();
  const message = useMessage();

  const { login } = useContext(AuthorizationContext);

  useEffect(() => {
    // message(errorMessage, true);
    clearErrorMessage();
  }, [errorMessage, message, clearErrorMessage]);

  useEffect(() => {
    if (error?.email) {
      message(error.email[0], true);
    } else if (error?.detail) {
      message(error?.detail, true);
    }
    clearError();
  }, [error, message, clearError]);

  const loginHandler = async (d: data) => {
    try {
      const data = await request(`${baseUrl}/auth/login/`, "POST", d);
      login(data.access, data.role, d);
    } catch (e) {}
  };

  return (
    <LoginScreen loading={loading} loginHandler={loginHandler} {...props} />
  );
};

export default LoginScreenContainer;
