import React, { createContext, useReducer } from "react";
import * as actions from "./../actions/actionsType";
import {
  coursesReducer,
  initialCoursesState,
} from "./../reducers/coursesReducer";
import { useAuth } from "./../../utils/hooks";

export const CoursesContext = createContext<any>(initialCoursesState);

const CoursesProvider: React.FC<React.ReactNode> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = useReducer(coursesReducer, initialCoursesState);

  const value = {
    coursesList: state.coursesList,
    setCourses: (coursesList: any) => {
      dispatch({
        type: actions.SET_COURSES_LIST,
        coursesList,
      });
    },
  };

  return (
    <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
  );
};

export default CoursesProvider;
